import type { LinkProps } from 'react-router-dom';

import { forwardRef } from 'react';
import { hotjar } from 'react-hotjar';
import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------

interface RouterLinkProps extends Omit<LinkProps, 'to'> {
  href: string;
}

export const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  ({ href, ...other }, ref) => {
    hotjar.stateChange(href);

    return <Link ref={ref} to={href} {...other} />;
  }
);
