import { useMemo, useCallback } from 'react';
import { useSearchParams as _useSearchParams } from 'react-router-dom';

// ----------------------------------------------------------------------

type SearchPropertyTypes = 'string' | 'number' | 'array';

type SearchPropertyValueType<Type extends SearchPropertyTypes> = Type extends 'string' ? string : Type extends 'number' ? number : Type extends 'array' ? string[] : any;

type GetSearchPropertyProps<Type extends SearchPropertyTypes> = {
  name: string,
  defaultValue: SearchPropertyValueType<Type>,
  partOf?: string[],
  type?: Type,
};

export function useSearchParams() {
  const [searchParams, setSearchParams] = _useSearchParams();

  const getSearchProperty = useCallback(<Type extends SearchPropertyTypes = 'string'>(
      {
        name,
        defaultValue,
        partOf,
        type = 'string' as Type,
      }: GetSearchPropertyProps<Type>
    ): SearchPropertyValueType<Type> => {
      const value = searchParams.get('search')?.split(';').find((param) => param.startsWith(`${name}:`))?.replace(`${name}:`, '');

      if (value) {
        switch (type) {
          case 'string':
            if (partOf && !partOf.includes(value)) {
              return defaultValue;
            }

            return value as SearchPropertyValueType<Type>;
          case 'number':
            if (partOf && !partOf.includes(value)) {
              return defaultValue;
            }

            return parseInt(value, 10) as SearchPropertyValueType<Type>;
          case 'array': {
            const array = value.split(',');

            if (partOf) {
              const validParts = array.filter((part) => partOf.includes(part));

              if (validParts.length > 0) {
                return validParts as SearchPropertyValueType<Type>;
              }

              return defaultValue;
            }

            return array as SearchPropertyValueType<Type>;
          }
          default:
            break;
        }
      }

      return defaultValue;
    },
    [searchParams],
  );

  const memoized = useMemo(() => ({
    searchParams,
    getSearchProperty,
  }), [searchParams, getSearchProperty]);

  return {
    ...memoized,
    setSearchParams,
  };
}
