import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { SplashScreen, LoadingScreen } from 'src/components/loading-screen';

import { ServerErrorView } from 'src/sections/error';

// ----------------------------------------------------------------------

// Error
const Page404 = lazy(() => import('src/pages/error/404'));

// ----------------------------------------------------------------------

export function MainRoutes() {
  return [
    {
      element: (
        <ErrorBoundary FallbackComponent={ServerErrorView}>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      ),
      children: [{ path: '404', element: <Page404 /> }],
    },
  ];
}

export const errorBoundary = (
  <ErrorBoundary FallbackComponent={ServerErrorView}>
    <Suspense fallback={<LoadingScreen />}>
      <Outlet />
    </Suspense>
  </ErrorBoundary>
);
