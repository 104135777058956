import type { BoxProps } from '@mui/material/Box';

import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import NoSsr from '@mui/material/NoSsr';

import { RouterLink } from 'src/routes/components';

import { logoClasses } from './classes';

// ----------------------------------------------------------------------

export type LogoProps = BoxProps & {
  href?: string;
  mini?: boolean;
  disableLink?: boolean;
};

export const Logo = forwardRef<HTMLDivElement, LogoProps>(
  (
    {
      width = 40,
      height = 40,
      mini = false,
      disableLink = false,
      className,
      href = '/',
      sx,
      ...other
    },
    ref,
  ) =>
    (
      <NoSsr
        fallback={
          <Box
            width={width}
            height={height}
            className={logoClasses.root.concat(className ? ` ${className}` : '')}
            sx={{flexShrink: 0, display: 'inline-flex', verticalAlign: 'middle', ...sx}}
          />
        }
      >
        <Box
          ref={ref}
          width={width}
          height={height}
          className={logoClasses.root.concat(className ? ` ${className}` : '')}
          aria-label="logo"
          sx={{
            flexShrink: 0,
            display: 'inline-flex',
            verticalAlign: 'middle',
            ...(disableLink && {pointerEvents: 'none'}),
            ...sx,
          }}
          {...(!disableLink && {
            component: RouterLink,
            href,
          })}
          {...other}
        >
          <Box alt="logo" component="img"
               src={`${window.location.origin}/logo/${mini ? 'logo-single.svg' : 'logo-full.svg'}`} width={width}
               height={height}/>
        </Box>
      </NoSsr>
    )
);
