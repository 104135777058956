import { lazy } from 'react';

import { Navigate } from 'src/routes/components';

import { MainLayout } from 'src/layouts/core';

import { RoleRouteGuard, AuthGuardWrapper } from 'src/auth/guard';

import { errorBoundary } from './main';

// ----------------------------------------------------------------------

const AccessRequestList = lazy(() => import('src/pages/management/access-requests'));
const ViewAccessRequest = lazy(() => import('src/pages/management/access-requests/view'));

const UserList = lazy(() => import('src/pages/management/users'));
const ViewUser = lazy(() => import('src/pages/management/users/view'));

// ----------------------------------------------------------------------

const layoutContent = (
  <MainLayout>
    {errorBoundary}
  </MainLayout>
);

export function ManagementRoutes() {
  return [
    {
      path: 'management',
      element: <AuthGuardWrapper>{layoutContent}</AuthGuardWrapper>,
      children: [
        {
          element: (
            <RoleRouteGuard requiredAccess="management.access_requests.view_all" navigateTo="/">
              <Navigate to="/management/access-requests" replace />
            </RoleRouteGuard>
          ),
          index: true,
        },
        {
          path: 'access-requests',
          children: [
            {
              element: (
                <RoleRouteGuard requiredAccess="management.access_requests.view_all" navigateTo="/">
                  <AccessRequestList />
                </RoleRouteGuard>
              ),
              index: true,
            },
            {
              path: 'view',
              children: [
                {
                  element: (
                    <RoleRouteGuard requiredAccess="management.access_requests.view_all" navigateTo="/">
                      <Navigate to="/management/access-requests" replace />
                    </RoleRouteGuard>
                  ),
                  index: true,
                },
                {
                  path: ':uuid',
                  element: (
                    <RoleRouteGuard requiredAccess="management.access_requests.view_all" navigateTo="/">
                      <ViewAccessRequest />
                    </RoleRouteGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'users',
          children: [
            {
              element: (
                <RoleRouteGuard requiredAccess="management.users.view_all" navigateTo="/">
                  <UserList />
                </RoleRouteGuard>
              ),
              index: true,
            },
            {
              path: 'view',
              children: [
                {
                  element: (
                    <RoleRouteGuard requiredAccess="management.users.view_all" navigateTo="/">
                      <Navigate to="/management/users" replace />
                    </RoleRouteGuard>
                  ),
                  index: true,
                },
                {
                  path: ':uuid',
                  element: (
                    <RoleRouteGuard requiredAccess="management.users.view_all" navigateTo="/">
                      <ViewUser />
                    </RoleRouteGuard>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ];
}
