import type { User } from 'src/types/auth/sanctum';

import { hotjar } from 'react-hotjar';
import { useSanctum } from 'react-sanctum';
import { useState, useEffect, useContext, useCallback } from 'react';

import { useRouter, usePathname, useSearchParams } from 'src/routes/hooks';

import { SplashScreen } from 'src/components/loading-screen';
import { ConfigContext } from 'src/components/config-injector';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode,
};

export function AuthGuard({ children }: Props) {
  const { carxo: { frontend: { auth: { login } } } } = useContext(ConfigContext);

  const router = useRouter();

  const pathname = usePathname();

  const { searchParams } = useSearchParams();

  const { user, authenticated, checkAuthentication } = useSanctum<User>();

  const [isChecking, setIsChecking] = useState<boolean>(true);

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);

      return params.toString();
    },
    [searchParams]
  );

  const checkPermissions = async (): Promise<void> => {
    if (authenticated === null) {
      await checkAuthentication();
      return;
    }

    if (!authenticated) {
      const href = `${login}?${createQueryString('returnTo', pathname)}`;

      router.replace(href);
    }

    if (user) {
      const { uuid, name, email, impersonated } = user;

      hotjar.identify(uuid, {
        name,
        email,
        impersonated: impersonated
          ? { name: impersonated.name, email: impersonated.email }
          : false
      });
    }

    setIsChecking(false);
  };

  useEffect(() => {
    checkPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  if (isChecking) {
    return <SplashScreen />;
  }

  return <>{children}</>;
}
