import type { FilledInputProps } from '@mui/material/FilledInput';
import type { AutocompleteProps as BaseAutocompleteProps } from '@mui/material/Autocomplete';

import SimpleBar from 'simplebar-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Paper from '@mui/material/Paper';
import BaseAutocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { TextField } from './text-field';

type AutocompleteProps = Omit<BaseAutocompleteProps<any, boolean, boolean, boolean>, 'renderInput'> & {
  inputProps?: {
    label?: string,
    placeholder?: string,
    error?: boolean,
    errorText?: string,
    value?: unknown,
    onChange?: FilledInputProps['onChange'],
    startAdornment?: React.ReactNode,
    endAdornment?: React.ReactNode,
  },
};

export function Autocomplete(
  {
    inputProps,
    multiple,
    disableClearable,
    disabled,
    value,
    sx,
    ...other
  }: AutocompleteProps
) {
  const { t } = useTranslation();

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 500,
  });

  const shrinkState = useMemo(() => multiple ? value.length > 0 : !!value, [multiple, value])

  const [shrink, setShrink] = useState(shrinkState);

  return <BaseAutocomplete
    filterOptions={filterOptions}
    noOptionsText={t('noOptions')}
    {...other}
    value={value}
    renderInput={(params) => (
      <TextField
        {...params}
        label={inputProps?.label}
        placeholder={inputProps?.placeholder}
        InputProps={{
          ...params.InputProps,
          startAdornment: inputProps?.startAdornment ?? params.InputProps.startAdornment,
          endAdornment: inputProps?.endAdornment ?? params.InputProps.endAdornment,
          sx: {
            'input.MuiAutocomplete-input': {
              paddingLeft: '0 !important',
            },
          },
        }}
        onFocus={() => setShrink(true)}
        onBlur={() => setShrink(shrinkState)}
        InputLabelProps={{
          shrink,
        }}
        value={inputProps?.value}
        onChange={inputProps?.onChange}
        error={inputProps?.error}
        errorText={inputProps?.errorText}
      />
    )}
    // ChipProps={{
    //   sx: {
    //     backgroundColor: 'rgba(var(--palette-grey-500Channel) / 0.12)',
    //     color: 'text.primary',
    //
    //     '&:hover': {
    //       backgroundColor: 'rgba(var(--palette-grey-500Channel) / 0.12)',
    //       color: 'text.primary',
    //     },
    //
    //     '.MuiChip-deleteIcon': {
    //       color: 'text.primary',
    //     },
    //
    //     '&.Mui-disabled': {
    //       color: 'var(--palette-text-primary)',
    //
    //       '.MuiChip-deleteIcon': {
    //         display: 'none',
    //       },
    //     },
    //   },
    // }}
    PaperComponent={
      (paperProps) => <Paper {...paperProps}>
        <SimpleBar children={paperProps.children} style={{ maxHeight: 200 }} scrollbarMaxSize={64}/>
      </Paper>
    }
    ListboxProps={{ sx: { overflow: 'hidden', maxHeight: 'unset' } }}
    multiple={multiple}
    disabled={disabled}
    disableCloseOnSelect={multiple}
    clearIcon={disableClearable ? null : undefined}
    sx={{
      width: 1,

      '.MuiOutlinedInput-root': { paddingLeft: '14px', height: 54 },

      // ...(
      //   disabled && {
      //     backgroundColor: 'var(--layout-disabled-input-background)',
      //   }
      // ),

      ...sx
    }}
  />
}
