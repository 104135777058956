export type ConfigValue = {
  configConnString: string;
};

// ----------------------------------------------------------------------

export const CONFIG: ConfigValue = {
  configConnString:
    import.meta.env.APP_CONFIG_CONN_STRING ??
    'Endpoint=https://kv-carxo.azconfig.io;Id=Qk5n;Secret=B8RrGbUbdgsLEqiUVOk53Chie9YKXOZEB0Tn48Js5X9p0hzvMyHiJQQJ99AJAChHRaEdQd15AAABAZAC2QLW',
};
