// TODO: Move this to utils.ts and split it between modules
import type { AxiosResponse } from 'axios';
import type { CartItem } from 'src/types/cart';
import type { NewsSegment } from 'src/types/news';
import type { Resource } from 'src/types/resources';
import type { SearchResultData } from 'src/types/search';
import type { Target, Attachment } from 'src/components/attachments';
import type { InventoryVehicle } from 'src/types/vehicle-card';
import type { Binding } from 'src/types/sales/app-user-binding';
import type { Event, EventData } from 'src/types/marketing/events';
import type { AccessRequest } from 'src/types/auth/request-access';
import type { ServiceActivity } from 'src/types/after-sales/service';
import type { EntityRecord } from 'src/types/administration/entities';
import type { RoleData } from 'src/types/administration/entities/role';
import type { Order, SparePart } from 'src/types/after-sales/spare-parts';
import type { Company, TableRowsProps, ListRequestProps } from 'src/types';
import type { UserData, UserOptions } from 'src/types/administration/entities/user';
import type { User, UserData as ManagementUserData } from 'src/types/management/users';
import type { SalesReport, SalesReportAggregate } from 'src/types/sales/sales-reporting';
import type { Order as SalesOrder, OrderData as SalesOrderData } from 'src/types/sales/orders';
import type { AccessRequest as MerchandiseAccessRequest } from 'src/types/marketing/merchandise';
import type { Reservation, InventoryVehicle as InventoryRecord } from 'src/types/sales/inventory';
import type { Order as AfterSalesOrder, OrderData as AfterSalesOrderData } from 'src/types/after-sales/orders';
import type { Modification, TechnicalCampaign, TechnicalCampaignData } from 'src/types/after-sales/technical-campaigns';
import type { AccessRequestData, AccessRequest as ManagementAccessRequest } from 'src/types/management/access-requests';
import type {
  Claim,
  ClaimData,
  LaborOption,
  SparePartOption,
  FaultEntityOptions,
} from 'src/types/after-sales/claims';
import type {
  Option,
  Booking,
  OrderRequest,
  OrderRequestData,
  ConfiguratorVehicle,
  Modification as RequestModification,
} from 'src/types/sales/order-requests';

import axios from 'axios';

import { paths } from 'src/routes/paths';

const axiosInstance = axios.create({
  withCredentials: false,
});

export function getGlobalSearchResults(query: string): Promise<SearchResultData> {
  return makeRequest<void, { data: SearchResultData }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/search/global?search=${query}`,
  }).then((response) => response.data.data);
}

export function getVehicleData(uuid: string): Promise<InventoryVehicle> {
  return makeRequest<void, { data: InventoryVehicle }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/inventory-vehicle/${uuid}`,
  }).then((response) => response.data.data);
}

export function getConfiguratorVehicles(): Promise<ConfiguratorVehicle[]> {
  return makeRequest<void, { data: ConfiguratorVehicle[] }>({
    type: 'get',
    url: 'https://portal2test.electricway.no/api/frontend/v1/configurator-vehicle?perPage=-1',
  }).then((response) => response.data.data);
}

export function getInventory(paginationProps: ListRequestProps): Promise<TableRowsProps<InventoryRecord>> {
  return makeListRequest<InventoryRecord>(
    {
      url: 'https://portal2test.electricway.no/api/frontend/v1/inventory-vehicle',
      paginationProps,
    }
  );
}

export function getClaims(paginationProps: ListRequestProps): Promise<TableRowsProps<Claim>> {
  return makeListRequest<Claim>(
    {
      url: `https://portal2test.electricway.no/api/frontend/v1/claim`,
      paginationProps,
    }
  );
}

export function getClaimData(uuid: string): Promise<ClaimData<'get'>> {
  return makeRequest<void, { data: ClaimData<'get'> }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/claim/${uuid}`,
  }).then((response) => response.data.data);
}

export function getOrderRequests(paginationProps: ListRequestProps): Promise<TableRowsProps<OrderRequest>> {
  return makeListRequest<OrderRequest>(
    {
      url: `https://portal2test.electricway.no/api/frontend/v1/claim`,
      paginationProps,
    }
  );
}

export function getOrderRequestData(uuid: string): Promise<OrderRequestData> {
  return makeRequest<void, { data: OrderRequestData }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/configurator-vehicle/${uuid}/service-interval`,
  }).then((response) => response.data.data);
}

export function getNews(type: string): Promise<NewsSegment[]> {
  return makeRequest<void, { data: NewsSegment[] }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/configurator-vehicle/${type}/service-interval`,
  }).then((response) => response.data.data);
}

export function getTechnicalCampaignData(uuid: string): Promise<TechnicalCampaignData<'get'>> {
  return makeRequest<void, { data: TechnicalCampaignData<'get'> }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/technical-campaign/${uuid}`,
  }).then((response) => response.data.data);
}

export function getEvents(paginationProps: ListRequestProps): Promise<TableRowsProps<Event>> {
  return makeListRequest<Event>(
    {
      url: `https://portal2test.electricway.no/api/frontend/v1/event`,
      paginationProps,
    }
  );
}

export function getEventData(uuid: string): Promise<EventData<'get'>> {
  return makeRequest<void, { data: EventData<'get'> }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/event/${uuid}`,
  }).then((response) => response.data.data);
}

export function getResources(type: string, path: string): Promise<Resource[]> {
  return makeRequest<void, { data: Resource[] }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/claim`,
  }).then((response) => response.data.data);
}

export function getVehicleOptions(uuid: string): Promise<Option[]> {
  return makeRequest<void, { data: Option[] }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/modification/${uuid}/vehicle-option`,
  }).then((response) => response.data.data);
}

export function getVehicleModifications(uuid: string): Promise<RequestModification[]> {
  return makeRequest<void, { data: RequestModification[] }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/configurator-vehicle/${uuid}/modification`,
  }).then((response) => response.data.data);
}

export function getModifications(): Promise<Modification[]> {
  return makeRequest<void, { data: Modification[] }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/modification?perPage=-1`,
  }).then((response) => response.data.data);
}

export function getPriceList(): Promise<Resource[]> {
  return makeRequest<void, { data: Resource[] }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/claim`,
  }).then((response) => response.data.data);
}

export function getSpareParts(paginationProps: ListRequestProps): Promise<TableRowsProps<SparePart>> {
  return makeListRequest<SparePart>(
    {
      url: `https://portal2test.electricway.no/api/frontend/v1/spare-parts`,
      paginationProps,
    }
  );
}

export function getSalesOrderData(uuid: string): Promise<SalesOrderData> {
  return makeRequest<void, { data: SalesOrderData }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/claim/${uuid}`,
  }).then((response) => response.data.data);
}

export function getAfterSalesOrderData(uuid: string): Promise<AfterSalesOrderData> {
  return makeRequest<void, { data: AfterSalesOrderData }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/claim/${uuid}`,
  }).then((response) => response.data.data);
}

export function getSalesOrders(paginationProps: ListRequestProps): Promise<TableRowsProps<SalesOrder>> {
  return makeListRequest<SalesOrder>(
    {
      url: `https://portal2test.electricway.no/api/frontend/v1/claim`,
      paginationProps,
    }
  );
}

export function getAfterSalesOrders(paginationProps: ListRequestProps): Promise<TableRowsProps<AfterSalesOrder>> {
  return makeListRequest<AfterSalesOrder>(
    {
      url: `https://portal2test.electricway.no/api/frontend/v1/claim`,
      paginationProps,
    }
  );
}

export function getCartItems(): Promise<{ items: CartItem[] }> {
  return makeRequest<void, { data: { items: CartItem[] } }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/cart`,
  }).then((response) => response.data.data);
}

export function addOrUpdateCartItem(code: string, quantity: number): Promise<{ items: CartItem[] }> {
  return makeRequest<{ code: string, quantity: number }, { data: { items: CartItem[] } }>({
    type: 'post',
    url: `https://portal2test.electricway.no/api/frontend/v1/cart/items`,
    data: { code, quantity },
  }).then((response) => response.data.data);
}

export function syncCartItems(items: CartItem[]): Promise<{ items: CartItem[] }> {
  return makeRequest<{ items: CartItem[] }, { data: { items: CartItem[] } }>({
    type: 'post',
    url: `https://portal2test.electricway.no/api/frontend/v1/cart/sync`,
    data: { items },
  }).then((response) => response.data.data);
}

export function getDashboardData<T>(type: string): Promise<T> {
  return makeRequest<void, { data: T }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/claim`,
  }).then((response) => response.data.data);
}

export function createAttachment(target: Target, attachment: File): Promise<Attachment> {
  return makeRequest<File, { data: Attachment }>({
    type: 'post',
    url: `https://portal2test.electricway.no/api/attachment/${target.type}${target.uuid ? `/${target.uuid}` : ''}`,
    data: attachment,
  }).then((response) => response.data.data);
}

export function getAttachment(uuid: string): Promise<Blob> {
  return makeRequest<void, string>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/attachment/${uuid}`,
  }).then((response) => new Blob([response.data], { type: response.headers['content-type'] }));
}

export function deleteAttachment(uuid: string): Promise<void> {
  return makeRequest<void, void>({
    type: 'delete',
    url: `https://portal2test.electricway.no/api/attachment/${uuid}`,
  }).then((response) => response.data);
}

export function createClaim(claim: ClaimData<'set'>): Promise<void> {
  return makeRequest<ClaimData<'set'>, void>({
    type: 'post',
    url: `https://portal2test.electricway.no/api/frontend/v1/claim`,
    data: claim,
  }).then((response) => response.data);
}

export function updateClaim(claim: ClaimData<'set'>): Promise<void> {
  return makeRequest<ClaimData<'set'>, void>({
    type: 'patch',
    url: `https://portal2test.electricway.no/api/frontend/v1/claim/${claim.uuid}`,
    data: claim,
  }).then((response) => response.data);
}

export function createReservation(reservation: Reservation): Promise<void> {
  return makeRequest<Reservation, void>({
    type: 'post',
    url: `https://portal2test.electricway.no/api/frontend/v1/inventory-vehicle/${reservation.inventoryVehicleUuid}/reserve`,
    data: reservation,
  }).then((response) => response.data);
}

export function updateOrderRequest(orderRequest: Partial<OrderRequest>): Promise<void> {
  return makeRequest<Partial<OrderRequest>, void>({
    type: 'patch',
    url: `https://portal2test.electricway.no/api/frontend/v1/claims`,
    data: orderRequest,
  }).then((response) => response.data);
}

export function createBooking(booking: Booking): Promise<void> {
  return makeRequest<Booking, void>({
    type: 'post',
    url: `https://portal2test.electricway.no/api/frontend/v1/book`,
    data: booking,
  }).then((response) => response.data);
}

export function createBinding(binding: Binding): Promise<void> {
  return makeRequest<Binding, void>({
    type: 'post',
    url: `https://portal2test.electricway.no/api/frontend/v1/claims`,
    data: binding,
  }).then((response) => response.data);
}

export function createMerchandiseAccessRequest(accessRequest: MerchandiseAccessRequest): Promise<void> {
  return makeRequest<MerchandiseAccessRequest, void>({
    type: 'post',
    url: `https://portal2test.electricway.no/api/frontend/v1/claims`,
    data: accessRequest,
  }).then((response) => response.data);
}

export function createEvent(event: EventData<'set'>): Promise<void> {
  return makeRequest<EventData<'set'>, void>({
    type: 'post',
    url: `https://portal2test.electricway.no/api/frontend/v1/event`,
    data: event,
  }).then((response) => response.data);
}

export function updateEvent(event: EventData<'set'>): Promise<void> {
  return makeRequest<EventData<'set'>, void>({
    type: 'patch',
    url: `https://portal2test.electricway.no/api/frontend/v1/event/${event.uuid}`,
    data: event,
  }).then((response) => response.data);
}

export function createServiceActivity(serviceActivity: ServiceActivity): Promise<void> {
  return makeRequest<ServiceActivity, void>({
    type: 'post',
    url: `https://portal2test.electricway.no/api/frontend/v1/inventory-vehicle/${serviceActivity.inventoryVehicleUuid}/service-history`,
    data: serviceActivity,
  }).then((response) => response.data);
}

export function updateServiceActivity(serviceActivity: ServiceActivity): Promise<void> {
  return makeRequest<ServiceActivity, void>({
    type: 'patch',
    url: `https://portal2test.electricway.no/api/frontend/v1/service-history/${serviceActivity.uuid}`,
    data: serviceActivity,
  }).then((response) => response.data);
}

export function createAccessRequest(accessRequest: AccessRequest): Promise<void> {
  return makeRequest<AccessRequest, void>({
    type: 'post',
    url: `https://portal2test.electricway.no/api/frontend/v1/access-request`,
    data: accessRequest,
  }).then((response) => response.data);
}

export function getLaborSearchResults(uuid: string, query: string): Promise<LaborOption[]> {
  return makeRequest<void, { data: LaborOption[] }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/modification/${uuid}/labor?search=${query}&perPage=500`,
  }).then(
    (response) => response.data.data
  ).catch(() => []);
}

export function getSparePartSearchResults(uuid: string, query: string): Promise<SparePartOption[]> {
  return makeRequest<void, { data: { spareParts: SparePartOption[] } }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/modification/${uuid}/spare-part?search=${query}&perPage=500`,
  }).then(
    (response) => response.data.data.spareParts
  ).catch(() => []);
}

export function getSalesReportAggregates(year: number): Promise<SalesReportAggregate[]> {
  return makeRequest<void, { data: SalesReportAggregate[] }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/claims/${year}`,
  }).then((response) => response.data.data);
}

export function createSalesReport(salesReport: SalesReport<'set'>): Promise<void> {
  return makeRequest<SalesReport<'set'>, void>({
    type: 'post',
    url: `https://portal2test.electricway.no/api/frontend/v1/claims`,
    data: salesReport,
  }).then((response) => response.data);
}

export function updateSalesReport(salesReport: SalesReport<'set'>): Promise<void> {
  return makeRequest<SalesReport<'set'>, void>({
    type: 'patch',
    url: `https://portal2test.electricway.no/api/frontend/v1/claims`,
    data: salesReport,
  }).then((response) => response.data);
}

export function getEntities(): Promise<string[]> {
  return makeRequest<void, { data: string[] }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/admin/v1/entity-list`,
  }).then((response) => response.data.data);
}

export function getEntityRecords(entity: string): (paginationProps: ListRequestProps) => Promise<TableRowsProps<EntityRecord>> {
  return (paginationProps: ListRequestProps): Promise<TableRowsProps<EntityRecord>> => makeListRequest<EntityRecord>(
    {
      url: `https://portal2test.electricway.no/api/admin/v1/entity/${entity}`,
      paginationProps,
    }
  );
}

export function deleteUser(uuid: string): Promise<void> {
  return makeRequest<void, void>({
    type: 'delete',
    url: `https://portal2test.electricway.no/api/frontend/v1/user/${uuid}`,
  }).then((response) => response.data);
}

export function getUserData(uuid: string): Promise<UserData<'get'>> {
  return makeRequest<void, { data: UserData<'get'> }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/claims`,
  }).then((response) => response.data.data);
}

export function updateUser(user: UserData<'set'>): Promise<void> {
  return makeRequest<UserData<'set'>, void>({
    type: 'patch',
    url: `https://portal2test.electricway.no/api/frontend/v1/claims`,
    data: user,
  }).then((response) => response.data);
}

export function getUserOptions(): Promise<UserOptions> {
  return makeRequest<void, { data: UserOptions }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/claims`,
  }).then((response) => response.data.data);
}

export function createUser(user: UserData<'set'>): Promise<void> {
  return makeRequest<UserData<'set'>, void>({
    type: 'post',
    url: `https://portal2test.electricway.no/api/frontend/v1/claims`,
    data: user,
  }).then((response) => response.data);
}

export function deleteRole(uuid: string): Promise<void> {
  return makeRequest<void, void>({
    type: 'delete',
    url: `https://portal2test.electricway.no/api/frontend/v1/role/${uuid}`,
  }).then((response) => response.data);
}

export function getRoleData(uuid: string): Promise<RoleData<'get'>> {
  return makeRequest<void, { data: RoleData<'get'> }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/claims/${uuid}`,
  }).then((response) => response.data.data);
}

export function updateRole(role: RoleData<'set'>): Promise<void> {
  return makeRequest<RoleData<'set'>, void>({
    type: 'patch',
    url: `https://portal2test.electricway.no/api/frontend/v1/claims`,
    data: role,
  }).then((response) => response.data);
}

export function createRole(role: RoleData<'set'>): Promise<void> {
  return makeRequest<RoleData<'set'>, void>({
    type: 'post',
    url: `https://portal2test.electricway.no/api/frontend/v1/claims`,
    data: role,
  }).then((response) => response.data);
}

export function getAccessRequests(paginationProps: ListRequestProps): Promise<TableRowsProps<ManagementAccessRequest>> {
  return makeListRequest<ManagementAccessRequest>(
    {
      url: `https://portal2test.electricway.no/api/admin/v1/access-request`,
      paginationProps,
    }
  );
}

export function getAccessRequestData(uuid: string): Promise<AccessRequestData<'get'>> {
  return makeRequest<void, { data: AccessRequestData<'get'> }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/admin/v1/access-request/${uuid}`,
  }).then((response) => response.data.data);
}

export function updateAccessRequest(accessRequest: AccessRequestData<'set'>): Promise<void> {
  return makeRequest<AccessRequestData<'set'>, void>({
    type: 'patch',
    url: `https://portal2test.electricway.no/api/admin/v1/access-request/${accessRequest.uuid}`,
    data: accessRequest,
  }).then((response) => response.data);
}

export function getUsers(paginationProps: ListRequestProps): Promise<TableRowsProps<User>> {
  return makeListRequest<User>(
    {
      url: `https://portal2test.electricway.no/api/frontend/v1/claim`,
      paginationProps,
    }
  );
}

export function getManagementUserData(uuid: string): Promise<ManagementUserData<'get'>> {
  return makeRequest<void, { data: ManagementUserData<'get'> }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/claims/${uuid}`,
  }).then((response) => response.data.data);
}

export function updateManagementUser(user: ManagementUserData<'set'>): Promise<void> {
  return makeRequest<ManagementUserData<'set'>, void>({
    type: 'patch',
    url: `https://portal2test.electricway.no/api/frontend/v1/claims`,
    data: user,
  }).then((response) => response.data);
}

export function createOrder(order: Order<'set'>): Promise<Order<'get'>> {
  return makeRequest<Order<'set'>, { data: Order<'get'> }>({
    type: 'post',
    url: `https://portal2test.electricway.no/api/frontend/v1/claims`,
    data: order,
  }).then((response) => response.data.data);
}

export function updateClaimStatus(claim: Partial<ClaimData<'set'>>): Promise<void> {
  return makeRequest<Partial<ClaimData<'set'>>, void>({
    type: 'patch',
    url: `https://portal2test.electricway.no/api/admin/v1/claim/${claim.uuid}`,
    data: claim,
  }).then((response) => response.data);
}

export function updateEventStatus(event: Partial<EventData<'set'>>): Promise<void> {
  return makeRequest<Partial<EventData<'set'>>, void>({
    type: 'patch',
    url: `https://portal2test.electricway.no/api/admin/v1/event/${event.uuid}`,
    data: event,
  }).then((response) => response.data);
}

export function getTechnicalCampaigns(paginationProps: ListRequestProps): Promise<TableRowsProps<TechnicalCampaign>> {
  return makeListRequest<TechnicalCampaign>(
    {
      url: `https://portal2test.electricway.no/api/frontend/v1/technical-campaign`,
      paginationProps,
    }
  );
}

export function getFaultEntityOptions(): Promise<FaultEntityOptions> {
  return makeRequest<void, { data: FaultEntityOptions }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/fault-entities`,
  }).then((response) => response.data.data);
}

export function createTechnicalCampaign(technicalCampaign: TechnicalCampaignData<'set'>): Promise<void> {
  return makeRequest<TechnicalCampaignData<'set'>, void>({
    type: 'post',
    url: `https://portal2test.electricway.no/api/frontend/v1/modification/${technicalCampaign.modificationUuid}/technical-campaign`,
    data: technicalCampaign,
  }).then((response) => response.data);
}

export function getCompanies(): Promise<Company[]> {
  return makeRequest<void, { data: Company[] }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/company?perPage=-1`,
  }).then((response) => response.data.data.map(({ uuid, name }) => ({ uuid, name })));
}

// ----------------------------------------------------------------------

type MakeRequestProps<Data> = {
  url: string,
} & ({
  type: 'get' | 'delete',
  data?: never,
} | {
  type: 'post' | 'patch',
  data: Data,
});

function makeRequest<Data, Response>({ type, url, data }: MakeRequestProps<Data>): Promise<AxiosResponse<Response, any>> {
  let request = null;

  switch (type) {
    case 'get':
      request = axiosInstance.get<Response>(url);
      break;
    case 'post':
      request = axiosInstance.post<Response>(url, data);
      break;
    case 'patch':
      request = axiosInstance.patch<Response>(url, data);
      break;
    case 'delete':
      request = axiosInstance.delete<Response>(url);
      break;
    default:
      throw new Error(`Unknown request type: ${type}`);
  }

  if (window.location.protocol === 'http:') {
    return request;
  }

  return request.catch((error) => {
    if (error.response?.status === 401) {
      window.location.href = paths.auth.login;
    }

    throw error;
  });
}

// ----------------------------------------------------------------------

type MakeListRequestProps = {
  url: string,
  paginationProps: ListRequestProps,
};

function makeListRequest<Entity>(
  {
    url,
    paginationProps,
  }: MakeListRequestProps
): Promise<TableRowsProps<Entity>> {
  const requestUrl = Object.entries(paginationProps).reduce((string: string, [key, value]) => {
    if (string.includes('?')) {
      return `${string}&${key}=${value}`;
    }

    return `${string}?${key}=${value}`;
  }, url);

  const request = axiosInstance
    .get<{ data: Entity[], meta: { total: number } }>(requestUrl)
    .then(({ data: { data, meta: { total } } }) => ({ data, total }));

  if (window.location.protocol === 'http:') {
    return request;
  }

  return request.catch((error) => {
    if (error.response?.status === 401) {
      window.location.href = paths.auth.login;
    }

    throw error;
  });
}
