import type { NavigateOptions } from 'react-router/dist/lib/context';

import { useMemo } from 'react';
import { hotjar } from 'react-hotjar';
import { useNavigate } from 'react-router-dom';

import { usePathname } from './use-pathname';

// ----------------------------------------------------------------------

export function useRouter() {
  const navigate = useNavigate();
  const pathname = usePathname();

  const router = useMemo(
    () => ({
      back: () => navigate(-1),
      forward: () => navigate(1),
      refresh: () => {
        hotjar.stateChange(pathname);
        navigate(0)
      },
      push: (href: string, options?: NavigateOptions) => {
        hotjar.stateChange(href);
        navigate(href, options);
      },
      replace: (href: string, options?: NavigateOptions) => {
        hotjar.stateChange(href);
        navigate(href, { ...options, replace: true });
      },
    }),
    [navigate, pathname]
  );

  return router;
}
