import type { OverridableStringUnion } from '@mui/types';
import type { Theme, SxProps } from '@mui/material/styles';
import type { ButtonPropsVariantOverrides } from '@mui/material/Button';

import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useBoolean } from 'src/hooks/use-boolean';

import { ConfirmDialog } from '../custom-dialog';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode,
  buttonProps?: {
    component?: any,
    startIcon?: React.ReactNode,
    endIcon?: React.ReactNode,
    variant?: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>,
    color?:  'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    size?: 'small' | 'medium' | 'large',
    disabled?: boolean,
    onClick?: (open: () => void) => void,
    disableRipple?: boolean,
    sx?: SxProps<Theme>,
  },
  dialogProps: {
    title?: string,
    content?: {
      element: React.ReactNode,
      sx?: SxProps<Theme>,
    },
    actions: {
      element: (close: () => void) => React.ReactNode,
      sx?: SxProps<Theme>,
    },
    sx?: SxProps<Theme>,
  },
};

export function ButtonWithConfirmation({ children, buttonProps, dialogProps }: Props) {
  const confirm = useBoolean();

  return (
    <>
      <Button
        {...buttonProps}
        onClick={buttonProps?.onClick ? () => buttonProps?.onClick!(confirm.onTrue) : confirm.onTrue}
      >
        {children}
      </Button>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={dialogProps?.title ? dialogProps.title : ''}
        {...(dialogProps?.content && {
          content: <DialogContent sx={dialogProps.content.sx}>
            {dialogProps.content.element}
          </DialogContent>
        })}
        actions={
          <DialogActions sx={dialogProps.actions.sx}>
            {dialogProps.actions.element(confirm.onFalse)}
          </DialogActions>
        }
        sx={dialogProps.sx}
      />
    </>
  );
}
