import { lazy } from 'react';

import { GuestGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

const SignIn = lazy(() => import('src/pages/auth/sign-in'));

const RequestAccess = lazy(() => import('src/pages/auth/request-access'));

// ----------------------------------------------------------------------

export function AuthRoutes() {
  return [
    {
      path: 'login',
      element: (
        <GuestGuard>
          <SignIn />
        </GuestGuard>
      ),
    },
    {
      path: 'request-access',
      element: (
        <GuestGuard>
          <RequestAccess />
        </GuestGuard>
      ),
    },
  ];
}
