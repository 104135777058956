import type { Theme, SxProps } from '@mui/material/styles';

import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import NoSsr from '@mui/material/NoSsr';

// ----------------------------------------------------------------------

export type FlagIconProps = {
  code?: string;
  sx?: SxProps<Theme>;
  language?: boolean;
};

export const FlagIcon = forwardRef<HTMLSpanElement, FlagIconProps>(
  ({ code, sx, language, ...other }, ref) => {
    const baseStyles = {
      width: 24,
      height: 24,
      flexShrink: 0,
      overflow: 'hidden',
      borderRadius: '50%',
      display: 'inline-flex',
      bgcolor: 'background.neutral',
    };

    const renderFallback = <Box component="span" sx={{ ...baseStyles, ...sx }} />;

    if (!code) {
      return null;
    }

    return (
      <NoSsr fallback={renderFallback}>
        <Box ref={ref} component="span" sx={{ ...baseStyles, ...sx }} {...other}>
          <Box
            component="img"
            alt={code}
            src={`${window.location.origin}/assets/icons/flagpack/${code?.toLowerCase()}.${language ? 'svg' : 'webp'}`}
            sx={{ width: 1, height: 1, objectFit: 'cover' }}
          />
        </Box>
      </NoSsr>
    );
  }
);
