import type { User } from 'src/types/auth/sanctum';

import { useSanctum } from 'react-sanctum';

import { _mock } from 'src/_mock';
// To get the user from the <AuthContext/>, you can use

// Change:
// import { useMockedUser } from 'src/auth/hooks';
// const { user } = useMockedUser();

// To:
// import { useAuthContext } from 'src/auth/hooks';
// const { user } = useAuthContext();

// ----------------------------------------------------------------------

export function useMockedUser() {
  const { user } = useSanctum<User>();

  const mockedUser = {
    id: user?.uuid ?? 'test-id',
    displayName: user?.name ?? 'test-name',
    email: user?.email ?? 'test-email',
    photoURL: _mock.image.avatar(24),
    phoneNumber: _mock.phoneNumber(1),
    country: _mock.countryNames(1),
    address: '90210 Broadway Blvd',
    state: 'California',
    city: 'San Francisco',
    zipCode: '94116',
    about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
    role: 'admin',
    isPublic: true,
  };

  return { mockedUser };
}
