import type { NavigateProps } from 'react-router-dom';

import { hotjar } from 'react-hotjar';
import { Navigate as BaseNavigate } from 'react-router-dom';

export function Navigate({ to, ...other }: NavigateProps) {
  hotjar.stateChange(typeof to === 'string' ? to : to.pathname!);

  return <BaseNavigate to={to} {...other} />
}
