import { useSanctum } from 'react-sanctum';
import { useState, useEffect, useContext } from 'react';

import { useRouter, useSearchParams } from 'src/routes/hooks';

import { SplashScreen } from 'src/components/loading-screen';
import { ConfigContext } from 'src/components/config-injector';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode,
};

export function GuestGuard({ children }: Props) {
  const { carxo: { frontend: { auth: { redirectPath } } } } = useContext(ConfigContext);

  const router = useRouter();

  const { searchParams } = useSearchParams();

  const { authenticated, checkAuthentication } = useSanctum();

  const [isChecking, setIsChecking] = useState<boolean>(true);

  const returnTo = searchParams.get('returnTo') || redirectPath;

  const checkPermissions = async (): Promise<void> => {
    if (authenticated === null) {
      await checkAuthentication();
      return;
    }

    if (authenticated) {
      router.replace(returnTo);
      return;
    }

    setIsChecking(false);
  };

  useEffect(() => {
    checkPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  if (isChecking) {
    return <SplashScreen />;
  }

  return <>{children}</>;
}
