import type { StackProps } from '@mui/material/Stack';

import { forwardRef } from 'react';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

import { varAlpha } from 'src/theme/styles';

import { Iconify } from 'src/components/iconify';
import { TextField } from 'src/components/fields';

// ----------------------------------------------------------------------

type Props = StackProps & {
  name?: string,
  quantity: number,
  disabledIncrease?: boolean,
  disabledDecrease?: boolean,
  onIncrease: () => void,
  onDecrease: () => void,
  onValueChange?: (value: number) => void,
};

export const IncrementerButton = forwardRef<HTMLDivElement, Props>(
  ({ quantity, onIncrease, onDecrease, disabledIncrease, disabledDecrease, onValueChange, sx, ...other }, ref) => (
    <Stack
      ref={ref}
      flexShrink={0}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        p: !onValueChange ? 0.5 : 0,
        width: !onValueChange ? 88 : 96,
        borderRadius: 1,
        typography: 'subtitle2',
      ...(!onValueChange && { border: (theme) => `solid 1px ${varAlpha(theme.vars.palette.grey['500Channel'], 0.2)}` }),
        ...sx,
      }}
      {...other}
    >
      <IconButton
        size="small"
        onClick={onDecrease}
        disabled={disabledDecrease}
        sx={{ borderRadius: 0.75 }}
      >
        <Iconify icon="eva:minus-fill" width={16} />
      </IconButton>

      {
        onValueChange
          ? <TextField
            type="number"
            value={quantity}
            inputProps={{
              min: 1,
              sx: {
                width: 44,
                height: 30,
                padding: 0.5,
                boxSizing: 'border-box',
                textAlign: 'center',
              },
            }}
            onChange={(e) => onValueChange(parseInt(e.target.value, 10))}
          />
          : <>{quantity}</>
      }

      <IconButton
        size="small"
        onClick={onIncrease}
        disabled={disabledIncrease}
        sx={{ borderRadius: 0.75 }}
      >
        <Iconify icon="mingcute:add-line" width={16} />
      </IconButton>
    </Stack>
  )
);
