// core (MUI)
import {
  nbNO as nbNOCore,
  svSE as svSECore,
  fiFI as fiFICore,
  deDE as deDECore,
  zhCN as zhCNCore,
} from '@mui/material/locale';
// date pickers (MUI)
import {
  enUS as enUSDate,
  nbNO as nbNODate,
  svSE as svSEDate,
  fiFI as fiFIDate,
  deDE as deDEDate,
  zhCN as zhCNDate,
} from '@mui/x-date-pickers/locales';
// data grid (MUI)
import {
  enUS as enUSDataGrid,
  nbNO as nbNODataGrid,
  svSE as svSEDataGrid,
  fiFI as fiFIDataGrid,
  deDE as deDEDataGrid,
  zhCN as zhCNDataGrid,
} from '@mui/x-data-grid/locales';

// ----------------------------------------------------------------------

export const allLangs = [
  {
    value: 'en',
    label: 'English',
    countryCode: 'GB',
    adapterLocale: 'en',
    numberFormat: { code: 'en-US', currency: 'USD' },
    systemValue: {
      components: { ...enUSDate.components, ...enUSDataGrid.components },
    },
  },
  {
    value: 'no',
    label: 'Norwegian',
    countryCode: 'NO',
    adapterLocale: 'nb',
    numberFormat: { code: 'nb-NO', currency: 'NOK' },
    systemValue: {
      components: { ...nbNOCore.components, ...nbNODate.components, ...nbNODataGrid.components },
    },
  },
  {
    value: 'sv',
    label: 'Swedish',
    countryCode: 'SE',
    adapterLocale: 'sv',
    numberFormat: { code: 'sv-SE', currency: 'SEK' },
    systemValue: {
      components: { ...svSECore.components, ...svSEDate.components, ...svSEDataGrid.components },
    },
  },
  {
    value: 'fi',
    label: 'Finnish',
    countryCode: 'FI',
    adapterLocale: 'fi',
    numberFormat: { code: 'fi-FI', currency: 'EUR' },
    systemValue: {
      components: { ...fiFICore.components, ...fiFIDate.components, ...fiFIDataGrid.components },
    },
  },
  {
    value: 'de',
    label: 'German',
    countryCode: 'DE',
    adapterLocale: 'de',
    numberFormat: { code: 'de-DE', currency: 'EUR' },
    systemValue: {
      components: { ...deDECore.components, ...deDEDate.components, ...deDEDataGrid.components },
    },
  },
  {
    value: 'zh',
    label: 'Chinese',
    countryCode: 'CN',
    adapterLocale: 'zh-CN',
    numberFormat: { code: 'zh-CN', currency: 'CNY' },
    systemValue: {
      components: { ...zhCNCore.components, ...zhCNDate.components, ...zhCNDataGrid.components },
    },
  },
];

/**
 * Country code:
 * https://flagcdn.com/en/codes.json
 *
 * Number format code:
 * https://gist.github.com/raushankrjha/d1c7e35cf87e69aa8b4208a8171a8416
 */
