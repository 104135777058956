import type { User } from 'src/types/auth/sanctum';

import { useMemo, useCallback } from 'react';
import { useSanctum } from 'react-sanctum';

export function useHasAccess(requiredAccess?: string | string[], operator: 'or' | 'and' | 'any' | 'not' = 'and') {
  // const { user } = useSanctum<User>();

  const user = useMemo(() => (
    {
      roles: [
        {
          access: [
            'general.vehicle_card.*',
            'sales.dashboard.*',
            'sales.news.*',
            'sales.inventory.*',
            'sales.order_requests.*',
            'sales.orders.*',
            'sales.sales_reporting.*',
            'sales.price_list.*',
            'sales.app_user_binding.*',
            'sales.resources.*',
            'after_sales.dashboard.*',
            'after_sales.news.*',
            'after_sales.service_history.*',
            'after_sales.claims.*',
            'after_sales.technical_campaigns.*',
            'after_sales.epc.*',
            'after_sales.spare_parts.*',
            'after_sales.orders.*',
            'after_sales.resources.*',
            'marketing.dashboard.*',
            'marketing.news.*',
            'marketing.merchandise.*',
            'marketing.events.*',
            'marketing.resources.*',
            'academy.news.*',
            'academy.resources.*',
            'academy.website.*',
            'management.access_requests.*',
            'management.users.*',
            'management.roles.*',
            'administration.entities.*',
            'administration.storage.*',
            'administration.smtp.*'
          ],
        },
      ],
    }
  ), []);

  const hasAccess = useMemo(() => {
    if (!user) {
      return false;
    }

    if (!requiredAccess) {
      return true;
    }

    if (!Array.isArray(requiredAccess)) {
      if (operator === 'not') {
        return user.roles.every((role) => role.access.every((access) => !access.includes(requiredAccess)));
      }

      if (operator === 'any') {
        return user.roles.some((role) => role.access.some((access) => access.includes(requiredAccess)));
      }

      const [region, section] = requiredAccess.split('.');

      return user.roles.some((role) => {
        if (role.access.includes(`${region}.${section}.*`)) {
          return true;
        }

        return role.access.includes(requiredAccess);
      });
    }

    const requiredSet = new Set<string>(requiredAccess);
    const availableSet = new Set<string>([]);

    user.roles.forEach((role) => {
      requiredAccess.forEach((required) => {
        const [region, section] = required.split('.');

        if (role.access.includes(`${region}.${section}.*`) && !availableSet.has(required)) {
          availableSet.add(required);
        }
      });

      role.access.forEach((access) => {
        if (requiredSet.has(access) && !availableSet.has(access)) {
          availableSet.add(access);
        }
      });
    });

    if (operator === 'not') {
      return availableSet.size === 0;
    }

    if (operator === 'or') {
      return [...Array.from(availableSet)].some((access) => requiredSet.has(access));
    }

    return availableSet.size === requiredSet.size
      && [...Array.from(availableSet)].every((access) => requiredSet.has(access));
  }, [user, requiredAccess, operator]);

  const getData = useCallback((data: any, fallback: any) => {
    if (!hasAccess) {
      return fallback;
    }

    return data;
  }, [hasAccess]);

  const getRedirectPath = useCallback(() => {
    let redirectPath = '/';

    user.roles.forEach((role) => {
      if (redirectPath !== '/') {
        return;
      }

      role.access.forEach((access) => {
        if (redirectPath !== '/') {
          return;
        }

        const [region, section, permission] = access.split('.');

        if (permission === '*' || permission.includes('view')) {
          switch (section) {
            case 'vehicle_card':
            case 'service_history':
            case 'technical_campaigns':
            case 'epc':
            case 'website':
              return;
            default:
              redirectPath += `${region.replaceAll('_', '-')}/${section.replaceAll('_', '-')}`;
          }
        }
      });
    });

    return redirectPath;
  }, [user.roles]);

  return useMemo(
    () => ({
      hasAccess,
      getData,
      getRedirectPath,
    }),
    [hasAccess, getData, getRedirectPath]
  );
}
