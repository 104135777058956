import { lazy } from 'react';

import { Navigate } from 'src/routes/components';

import { MainLayout } from 'src/layouts/core';

import { useHasAccess } from 'src/auth/hooks';
import { PathGuard, RoleRouteGuard, AuthGuardWrapper } from 'src/auth/guard';

import { errorBoundary } from './main';
import { companyOptionsLoader } from '../utils';

// ----------------------------------------------------------------------

const Dashboard = lazy(() => import('src/pages/marketing/dashboard'));

const NewsList = lazy(() => import('src/pages/marketing/news'));
const ViewNews = lazy(() => import('src/pages/marketing/news/view'));

const Merchandise = lazy(() => import('src/pages/marketing/merchandise'));
const ApplyForAccess = lazy(() => import('src/pages/marketing/merchandise/apply'));

const EventList = lazy(() => import('src/pages/marketing/events'));
const ViewEvent = lazy(() => import('src/pages/marketing/events/view'));
const NewEvent = lazy(() => import('src/pages/marketing/events/new'));

const ResourceList = lazy(() => import('src/pages/marketing/resources'));

// ----------------------------------------------------------------------

const layoutContent = (
  <MainLayout>
    {errorBoundary}
  </MainLayout>
);

export function MarketingRoutes() {
  const { hasAccess } = useHasAccess('marketing.events.view_all');

  return [
    {
      path: 'marketing',
      element: <AuthGuardWrapper>{layoutContent}</AuthGuardWrapper>,
      children: [
        {
          element: (
            <RoleRouteGuard requiredAccess="marketing.dashboard.view_all" navigateTo="/">
              <Navigate to="/marketing/dashboard" replace />
            </RoleRouteGuard>
          ),
          index: true,
        },
        {
          path: 'dashboard',
          element: (
            <RoleRouteGuard requiredAccess="marketing.dashboard.view_all" navigateTo="/">
              <Dashboard />
            </RoleRouteGuard>
          ),
        },
        {
          path: 'news',
          children: [
            {
              element: (
                <RoleRouteGuard requiredAccess="marketing.news.view_all" navigateTo="/">
                  <NewsList />
                </RoleRouteGuard>
              ),
              index: true,
            },
            {
              path: 'view',
              element: (
                <RoleRouteGuard requiredAccess="marketing.news.view_all" navigateTo="/">
                  <ViewNews />
                </RoleRouteGuard>
              ),
            },
          ],
        },
        {
          path: 'merchandise',
          children: [
            {
              element: (
                <RoleRouteGuard requiredAccess="marketing.merchandise.view_all" navigateTo="/">
                  <Merchandise />
                </RoleRouteGuard>
              ),
              index: true,
            },
            {
              path: 'apply',
              children: [
                {
                  element: (
                    <RoleRouteGuard requiredAccess="marketing.merchandise.view_all" navigateTo="/">
                      <Navigate to="/marketing/merchandise" replace />
                    </RoleRouteGuard>
                  ),
                  index: true,
                },
                {
                  path: ':type',
                  element: (
                    <RoleRouteGuard requiredAccess="marketing.merchandise.create" navigateTo="/">
                      <ApplyForAccess />
                    </RoleRouteGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'events',
          children: [
            {
              element: (
                <RoleRouteGuard
                  requiredAccess={['marketing.events.view_related', 'marketing.events.view_all_related', 'marketing.events.view_all']}
                  operator="or"
                  navigateTo="/"
                >
                  <EventList />
                </RoleRouteGuard>
              ),
              loader: () => companyOptionsLoader(hasAccess),
              shouldRevalidate: () => false,
              index: true,
            },
            {
              path: 'view',
              children: [
                {
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['marketing.events.view_related', 'marketing.events.view_all_related', 'marketing.events.view_all']}
                      operator="or"
                      navigateTo="/"
                    >
                      <Navigate to="/marketing/events" replace />
                    </RoleRouteGuard>
                  ),
                  index: true,
                },
                {
                  path: ':uuid',
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['marketing.events.view_related', 'marketing.events.view_all_related', 'marketing.events.view_all']}
                      operator="or"
                      navigateTo="/"
                    >
                      <ViewEvent />
                    </RoleRouteGuard>
                  ),
                },
              ],
            },
            {
              path: 'new',
              element: (
                <RoleRouteGuard requiredAccess="marketing.events.create" navigateTo="/">
                  <NewEvent />
                </RoleRouteGuard>
              ),
            },
          ],
        },
        {
          path: 'resources',
          children: [
            {
              element: (
                <RoleRouteGuard requiredAccess="marketing.resources.view_all" navigateTo="/">
                  <Navigate to="/marketing/resources/path=" replace />
                </RoleRouteGuard>
              ),
              index: true,
            },
            {
              path: ':path',
              element: (
                <RoleRouteGuard requiredAccess="marketing.resources.view_all" navigateTo="/">
                  <PathGuard navigateTo="/marketing/resources/path=">
                    <ResourceList />
                  </PathGuard>
                </RoleRouteGuard>
              ),
            },
          ],
        },
      ],
    },
  ];
}
