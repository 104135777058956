import { lazy } from 'react';

import { Navigate } from 'src/routes/components';

import { MainLayout } from 'src/layouts/core';

import { RoleRouteGuard, AuthGuardWrapper } from 'src/auth/guard';

import { errorBoundary } from './main';

// ----------------------------------------------------------------------

const EntityList = lazy(() => import('src/pages/administration/entities'));
const ViewUser = lazy(() => import('src/pages/administration/entities/view/user'));
const ViewRole = lazy(() => import('src/pages/administration/entities/view/role'));
const ViewCompany = lazy(() => import('src/pages/administration/entities/view/company'));
const ViewClaim = lazy(() => import('src/pages/administration/entities/view/claim'));
const ViewEvent = lazy(() => import('src/pages/administration/entities/view/event'));
const NewUser = lazy(() => import('src/pages/administration/entities/new/user'));
const NewRole = lazy(() => import('src/pages/administration/entities/new/role'));
const NewCompany = lazy(() => import('src/pages/administration/entities/new/company'));
const NewClaim = lazy(() => import('src/pages/administration/entities/new/claim'));
const NewEvent = lazy(() => import('src/pages/administration/entities/new/event'));

const Storage = lazy(() => import('src/pages/administration/storage'));

const Smtp = lazy(() => import('src/pages/administration/smtp'));

// ----------------------------------------------------------------------

const layoutContent = (
  <MainLayout>
    {errorBoundary}
  </MainLayout>
);

export function AdministrationRoutes() {
  return [
    {
      path: 'administration',
      element: <AuthGuardWrapper>{layoutContent}</AuthGuardWrapper>,
      children: [
        {
          element: (
            <RoleRouteGuard requiredAccess="administration.entities.view_all" navigateTo="/">
              <Navigate to="/administration/entities" replace />
            </RoleRouteGuard>
          ),
          index: true,
        },
        {
          path: 'entities',
          children: [
            {
              element: (
                <RoleRouteGuard requiredAccess="administration.entities.view_all" navigateTo="/">
                  <EntityList />
                </RoleRouteGuard>
              ),
              index: true,
            },
            {
              path: 'view',
              children: [
                {
                  element: (
                    <RoleRouteGuard requiredAccess="administration.entities.view_all" navigateTo="/">
                      <Navigate to="/administration/entities" replace />
                    </RoleRouteGuard>
                  ),
                  index: true,
                },
                {
                  path: 'user',
                  children: [
                    {
                      element: (
                        <RoleRouteGuard requiredAccess="administration.entities.view_all" navigateTo="/">
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                      index: true,
                    },
                    {
                      path: ':uuid',
                      element: (
                        <RoleRouteGuard requiredAccess="administration.entities.view_all" navigateTo="/">
                          <ViewUser />
                        </RoleRouteGuard>
                      ),
                    },
                  ],
                },
                {
                  path: 'role',
                  children: [
                    {
                      element: (
                        <RoleRouteGuard requiredAccess="administration.entities.view_all" navigateTo="/">
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                      index: true,
                    },
                    {
                      path: ':uuid',
                      element: (
                        <RoleRouteGuard requiredAccess="administration.entities.view_all" navigateTo="/">
                          <ViewRole />
                        </RoleRouteGuard>
                      ),
                    },
                  ],
                },
                {
                  path: 'company',
                  children: [
                    {
                      element: (
                        <RoleRouteGuard requiredAccess="administration.entities.view_all" navigateTo="/">
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                      index: true,
                    },
                    {
                      path: ':uuid',
                      element: (
                        <RoleRouteGuard requiredAccess="administration.entities.view_all" navigateTo="/">
                          <ViewCompany />
                        </RoleRouteGuard>
                      ),
                    },
                  ],
                },
                {
                  path: 'claim',
                  children: [
                    {
                      element: (
                        <RoleRouteGuard requiredAccess="administration.entities.view_all" navigateTo="/">
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                      index: true,
                    },
                    {
                      path: ':uuid',
                      element: (
                        <RoleRouteGuard requiredAccess="administration.entities.view_all" navigateTo="/">
                          <ViewClaim />
                        </RoleRouteGuard>
                      ),
                    },
                  ],
                },
                {
                  path: 'event',
                  children: [
                    {
                      element: (
                        <RoleRouteGuard requiredAccess="administration.entities.view_all" navigateTo="/">
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                      index: true,
                    },
                    {
                      path: ':uuid',
                      element: (
                        <RoleRouteGuard requiredAccess="administration.entities.view_all" navigateTo="/">
                          <ViewEvent />
                        </RoleRouteGuard>
                      ),
                    },
                  ],
                },
              ],
            },
            {
              path: 'new',
              children: [
                {
                  element: (
                    <RoleRouteGuard requiredAccess="administration.entities.view_all" navigateTo="/">
                      <Navigate to="/administration/entities" replace />
                    </RoleRouteGuard>
                  ),
                  index: true,
                },
                {
                  path: 'user',
                  element: (
                    <RoleRouteGuard requiredAccess="administration.entities.create" navigateTo="/">
                      <NewUser />
                    </RoleRouteGuard>
                  ),
                },
                {
                  path: 'role',
                  element: (
                    <RoleRouteGuard requiredAccess="administration.entities.create" navigateTo="/">
                      <NewRole />
                    </RoleRouteGuard>
                  ),
                },
                {
                  path: 'company',
                  element: (
                    <RoleRouteGuard requiredAccess="administration.entities.create" navigateTo="/">
                      <NewCompany />
                    </RoleRouteGuard>
                  ),
                },
                {
                  path: 'claim',
                  element: (
                    <RoleRouteGuard requiredAccess="administration.entities.create" navigateTo="/">
                      <NewClaim />
                    </RoleRouteGuard>
                  ),
                },
                {
                  path: 'event',
                  element: (
                    <RoleRouteGuard requiredAccess="administration.entities.create" navigateTo="/">
                      <NewEvent />
                    </RoleRouteGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'storage',
          element: (
            <RoleRouteGuard requiredAccess="administration.storage.view_all" navigateTo="/">
              <Storage />
            </RoleRouteGuard>
          ),
        },
        {
          path: 'smtp',
          element: (
            <RoleRouteGuard requiredAccess="administration.smtp.view_all" navigateTo="/">
              <Smtp />
            </RoleRouteGuard>
          ),
        },
      ],
    },
  ];
}
