import { useHasAccess } from '../hooks';

// ----------------------------------------------------------------------

type Props = {
  requiredAccess: string | string[],
  operator?: 'or' | 'and' | 'any' | 'not',
  children: React.ReactNode,
  fallback?: React.ReactNode,
};

export function RoleComponentGuard({ requiredAccess, operator = 'and', children, fallback }: Props) {
  const { hasAccess } = useHasAccess(requiredAccess, operator);

  if (!hasAccess) {
    return fallback ?? false;
  }

  return <>{children}</>;
}
