import type { CartItem as CartItemProps } from 'src/types/cart';

import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { convertToCurrency } from 'src/utils/format-number';

import { useCartStore, useCommentStore } from 'src/stores/cart';

import { TextField } from 'src/components/fields';
import { IncrementerButton } from 'src/components/custom-inputs/incrementer-button';

// ----------------------------------------------------------------------

type Props = {
  cartItem: CartItemProps,
};

export function CartItem({ cartItem }: Props) {
  const { t } = useTranslation();

  const pending = useCartStore((state) => state.itemPending);
  const updateItem = useCartStore((state) => state.updateItem);
  const removeItem = useCartStore((state) => state.removeItem);

  const getComment = useCommentStore((state) => state.getComment);
  const setComment = useCommentStore((state) => state.setComment);
  const removeComment = useCommentStore((state) => state.removeComment);

  const [quantity, setQuantity] = useState(cartItem.quantity);
  const [itemComment, setItemComment] = useState(getComment(cartItem.code));

  const removeAction = useCallback(() => {
    removeItem(cartItem.code);
    removeComment(cartItem.code);
  }, [removeItem, cartItem.code, removeComment]);

  const increaseQuantityAction = useCallback(() => {
    const newState = quantity + 1;

    updateItem(cartItem.code, newState);

    setQuantity(newState);
  }, [quantity, updateItem, cartItem.code]);

  const decreaseQuantityAction = useCallback(() => {
    const newState = quantity - 1;

    updateItem(cartItem.code, newState);

    setQuantity(newState);
  }, [quantity, cartItem.code, updateItem]);

  const changeQuantity = useCallback((newState: number) => {
    updateItem(cartItem.code, newState);

    setQuantity(newState);
  }, [updateItem, cartItem.code]);

  useEffect(() => {
    if (!pending) {
      setQuantity(cartItem.quantity);
    }
  }, [pending, cartItem.quantity, quantity]);

  useEffect(() => {
    setComment(cartItem.code, itemComment)
  }, [setComment, cartItem.code, itemComment]);

  return (
    <Box
      component="li"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pb: 3,
        borderBottom: '1px dashed rgba(var(--palette-grey-500Channel) / 0.2)',

        '&:not(:first-of-type)': {
          pt: 3,
        },

        '&:last-of-type': {
          borderBottom: 'none',
          pb: 0,
        },
      }}
    >
      {
        (cartItem.quantity !== quantity && pending) &&
        <Stack sx={{ gap: 3 }}>
          <Stack sx={{ flexDirection: 'row' }}>
            <Typography
              variant="body2"
              sx={{ width: '50%' }}
            >
              {cartItem.description}
            </Typography>

            <Typography
              variant="body2"
              sx={{ display: 'flex', justifyContent: 'flex-end', width: '50%', color: 'transparent' }}
            >
              <Skeleton>
                {convertToCurrency(cartItem.lineAmount)}
              </Skeleton>
            </Typography>
          </Stack>

          <Stack sx={{ gap: 1.5 }}>
            <Stack sx={{ flexDirection: 'row', alignItems: 'flex-start' }}>
              <Typography
                variant="body2"
                color="text.disabled"
                sx={{ width: '50%', pt: 0.25 }}
              >
                {cartItem.code}
              </Typography>

              <Stack sx={{ width: '50%', alignItems: 'flex-end' }}>
                <Stack sx={{ gap: 0.75, alignItems: 'center' }}>
                  <Skeleton>
                    <IncrementerButton
                      name="quantity"
                      quantity={quantity}
                      onIncrease={increaseQuantityAction}
                      onDecrease={decreaseQuantityAction}
                      onValueChange={changeQuantity}
                    />
                  </Skeleton>

                  <Skeleton>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 10,
                        lineHeight: '22px',
                        width: 'fit-content',
                        color: 'error.main',
                        cursor: 'pointer'
                      }}
                      onClick={removeAction}
                    >
                      {t('remove')}
                    </Typography>
                  </Skeleton>
                </Stack>
              </Stack>
            </Stack>

            <Stack>
              <TextField
                multiline
                minRows={2}
                maxRows={3}
                label={t('comments')}
                sx={{ width: 1 }}
                value={itemComment}
                onChange={(e) => setItemComment(e.target.value)}
              />
            </Stack>

            {
              quantity > cartItem.availableQuantity &&
              <Stack>
                <Typography
                  variant="body2"
                  sx={{ fontSize: 12, lineHeight: '22px', color: 'error.main' }}
                >
                  {t('partsWillBeDeliveredLater', { amount: quantity - cartItem.availableQuantity })}
                </Typography>
              </Stack>
            }
          </Stack>
        </Stack>
      }

      {
        (cartItem.quantity === quantity || !pending) &&
        <Stack sx={{ gap: 3 }}>
          <Stack sx={{ flexDirection: 'row' }}>
            <Typography
              variant="body2"
              sx={{ width: '50%' }}
            >
              {cartItem.description}
            </Typography>

            <Typography
              variant="body2"
              sx={{ width: '50%', textAlign: 'right' }}
            >
              {convertToCurrency(cartItem.lineAmount)}
            </Typography>
          </Stack>

          <Stack sx={{ gap: 1.5 }}>
            <Stack sx={{ flexDirection: 'row', alignItems: 'flex-start' }}>
              <Typography
                variant="body2"
                color="text.disabled"
                sx={{ width: '50%', pt: 0.25 }}
              >
                {cartItem.code}
              </Typography>

              <Stack sx={{ width: '50%', alignItems: 'flex-end' }}>
                <Stack sx={{ gap: 0.75, alignItems: 'center' }}>
                  <IncrementerButton
                    name="quantity"
                    quantity={quantity}
                    onIncrease={increaseQuantityAction}
                    onDecrease={decreaseQuantityAction}
                    onValueChange={changeQuantity}
                  />

                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 10,
                      lineHeight: '22px',
                      width: 'fit-content',
                      color: 'error.main',
                      cursor: 'pointer'
                    }}
                    onClick={removeAction}
                  >
                    {t('remove')}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack>
              <TextField
                multiline
                minRows={2}
                maxRows={3}
                label={t('comments')}
                sx={{ width: 1 }}
                value={itemComment}
                onChange={(e) => setItemComment(e.target.value)}
              />
            </Stack>

            {
              quantity > cartItem.availableQuantity &&
              <Stack>
                <Typography
                  variant="body2"
                  sx={{ fontSize: 12, lineHeight: '22px', color: 'error.main' }}
                >
                  {t('partsWillBeDeliveredLater', { amount: quantity - cartItem.availableQuantity })}
                </Typography>
              </Stack>
            }
          </Stack>
        </Stack>
      }
    </Box>
  );
}
