import type { CartItem } from 'src/types/cart';

import { create } from 'zustand';

import { getCartItems, syncCartItems, addOrUpdateCartItem } from 'src/hooks/use-request';

import { convertToCurrency } from 'src/utils/format-number';

type CartState = {
  initialized: boolean,
  itemPending: boolean,
  itemsPending: boolean,
  open: boolean,
  items: CartItem[],
  total: number,
  openCart: () => void,
  closeCart: () => void,
  setItems: () => Promise<void>,
  addItem: (code: string, quantity: number) => Promise<void>,
  updateItem: (code: string, quantity: number) => Promise<void>,
  removeItem: (code: string) => Promise<void>,
  syncItems: () => Promise<void>,
  getTotal: (items: CartItem[]) => number,
};

export const useCartStore = create<CartState>()((set, get) => ({
  initialized: false,
  itemPending: false,
  itemsPending: false,
  open: false,
  items: [],
  total: 0,
  openCart: () => set({ open: true }),
  closeCart: () => set({ open: false }),
  setItems: async () => {
    if (!get().initialized) {
      set({ itemsPending: true });

      const { items } = await getCartItems();

      set({ initialized: true, itemsPending: false, items, total: get().getTotal(items) });
    }
  },
  addItem: async (code: string, quantity: number) => {
    const itemInCart = get().items.find((item) => item.code === code);

    set({ itemsPending: true });

    const { items } = await addOrUpdateCartItem(code, itemInCart ? quantity + itemInCart.quantity : quantity);

    set({ itemsPending: false, items, total: get().getTotal(items) });
  },
  updateItem: async (code: string, quantity: number) => {
    if (quantity < 1) {
      await get().removeItem(code);
      return;
    }

    set({ itemPending: true });

    const { items } = await addOrUpdateCartItem(code, quantity);

    set({ itemPending: false, items, total: get().getTotal(items) });
  },
  removeItem: async (code: string) => {
    set({ itemsPending: true });

    const { items } = await addOrUpdateCartItem(code, 0);

    set({ itemsPending: false, items, total: get().getTotal(items) });
  },
  syncItems: async () => {
    set({ itemsPending: true });

    const { items } = await syncCartItems(get().items);

    set({ itemsPending: false, items, total: get().getTotal(items) });
  },
  getTotal: (items: CartItem[]) => items.reduce((map, item) => {
    map += convertToCurrency(item.lineAmount, 'number');
    return map;
  }, 0),
}));
