import InputAdornment from '@mui/material/InputAdornment';

import { Iconify } from 'src/components/iconify';

// ----------------------------------------------------------------------

export function SearchAdornment() {
  return (
    <InputAdornment position="start" sx={{ marginRight: '28px' }}>
      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', position: 'absolute' }} />
    </InputAdornment>
  );
}
