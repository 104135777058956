import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import type { ConfirmDialogProps } from './types';

// ----------------------------------------------------------------------

export function ConfirmDialog({
  title,
  content,
  actions,
  open,
  onClose,
  sx,
  ...other
}: ConfirmDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={() => ({
        '.MuiPaper-root': {
          ...sx,
        },
      })}
      {...other}
    >
      <DialogTitle sx={{ padding: 0 }}>{title}</DialogTitle>

      {content && <DialogContent sx={{ typography: 'body2' }}>{content}</DialogContent>}

      {actions}
    </Dialog>
  );
}
