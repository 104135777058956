import { useParams } from 'src/routes/hooks';
import { Navigate } from 'src/routes/components';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode,
  navigateTo: string,
};

type Params = {
  path: string,
};

export function PathGuard({ children, navigateTo }: Props) {
  const { path } = useParams() as Params;

  if (path.slice(0, 5) !== 'path=') {
    return <Navigate to={navigateTo} />;
  }

  return <>{children}</>;
}
