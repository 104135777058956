import { omit } from 'lodash';
import { create } from 'zustand';

type CommentState = {
  comments: { [key: string]: string },
  getComment: (code: string) => string,
  setComment: (code: string, comment: string) => void,
  removeComment: (code: string) => void,
};

export const useCommentStore = create<CommentState>()((set, get) => ({
  comments: {},
  getComment: (code: string) => {
    if (!(code in get().comments)) {
      return '';
    }

    return get().comments[code];
  },
  setComment: (code: string, comment: string) => {
    set((state) => ({ comments: { ...state.comments, [code]: comment } }));
  },
  removeComment: (code: string) => {
    set((state) => ({ comments: { ...omit(state.comments, code) } }));
  },
}));
