import { lazy } from 'react';

import { Navigate } from 'src/routes/components';

import { MainLayout } from 'src/layouts/core';

import { PathGuard, RoleRouteGuard, AuthGuardWrapper } from 'src/auth/guard';

import { errorBoundary } from './main';

// ----------------------------------------------------------------------

const NewsList = lazy(() => import('src/pages/academy/news'));
const ViewNews = lazy(() => import('src/pages/academy/news/view'));

const ResourceList = lazy(() => import('src/pages/academy/resources'));

// ----------------------------------------------------------------------

const layoutContent = (
  <MainLayout>
    {errorBoundary}
  </MainLayout>
);

export function AcademyRoutes() {
  return [
    {
      path: 'academy',
      element: <AuthGuardWrapper>{layoutContent}</AuthGuardWrapper>,
      children: [
        {
          element: (
            <RoleRouteGuard requiredAccess="academy.news.view_all" navigateTo="/">
              <Navigate to="/academy/news" replace />
            </RoleRouteGuard>
          ),
          index: true,
        },
        {
          path: 'news',
          children: [
            {
              element: (
                <RoleRouteGuard requiredAccess="academy.news.view_all" navigateTo="/">
                  <NewsList />
                </RoleRouteGuard>
              ),
              index: true,
            },
            {
              path: 'view',
              element: (
                <RoleRouteGuard requiredAccess="academy.news.view_all" navigateTo="/">
                  <ViewNews />
                </RoleRouteGuard>
              ),
            },
          ],
        },
        {
          path: 'resources',
          children: [
            {
              element: (
                <RoleRouteGuard requiredAccess="academy.resources.view_all" navigateTo="/">
                  <Navigate to="/academy/resources/path=" replace />
                </RoleRouteGuard>
              ),
              index: true,
            },
            {
              path: ':path',
              element: (
                <RoleRouteGuard requiredAccess="academy.resources.view_all" navigateTo="/">
                  <PathGuard navigateTo="/academy/resources/path=">
                    <ResourceList />
                  </PathGuard>
                </RoleRouteGuard>
              ),
            },
          ],
        },
      ],
    },
  ];
}
