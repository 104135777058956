import type { TextFieldProps as BaseTextFieldProps } from '@mui/material/TextField';

import Typography from '@mui/material/Typography';
import BaseTextField from '@mui/material/TextField';

type TextFieldProps = Omit<BaseTextFieldProps, 'helperText'> & {
  errorText?: string,
};

export function TextField({ error, errorText, disabled, ...other }: TextFieldProps) {
  return <BaseTextField
    {...other}
    error={error}
    helperText={!error ? false : <Typography variant="subtitle2" sx={{ color: 'error.main' }}>{errorText}</Typography>}
    // {...(disabled &&
    //  {
    //    InputProps: {
    //      sx: { 'input': { backgroundColor: 'var(--layout-disabled-input-background)', borderRadius: 'inherit' } }
    //    }
  //  }
    // )}
  />
}
