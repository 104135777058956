import type { Workshop } from 'src/types/cart';
import type { User } from 'src/types/auth/sanctum';
import type { OrderItem } from 'src/types/after-sales/spare-parts';

import { m } from 'framer-motion';
import { useSanctum } from 'react-sanctum';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Radio from '@mui/material/Radio';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { createOrder } from 'src/hooks/use-request';

import { DATA } from 'src/_mock/data';
import { useCartStore, useCommentStore } from 'src/stores/cart';

import { toast } from 'src/components/snackbar';
import { varHover } from 'src/components/animate';
import { Scrollbar } from 'src/components/scrollbar';
import { LoadingScreen } from 'src/components/loading-screen';
import { TextField, Autocomplete } from 'src/components/fields';
import { ButtonWithConfirmation } from 'src/components/buttons';

import { CartItem } from './cart-item';

// ----------------------------------------------------------------------

export function CartDrawer() {
  const { t } = useTranslation();

  const router = useRouter();

  const pending = useCartStore((state) => state.itemsPending);
  const open = useCartStore((state) => state.open);
  const items = useCartStore((state) => state.items);
  const total = useCartStore((state) => state.total);
  const openCart = useCartStore((state) => state.openCart);
  const closeCart = useCartStore((state) => state.closeCart);
  const setItems = useCartStore((state) => state.setItems);
  const syncItems = useCartStore((state) => state.syncItems);

  const getComment = useCommentStore((state) => state.getComment);

  const { user } = useSanctum<User>();

  const [workshop, setWorkshop] = useState<Workshop | null>(
    user?.workshops
      ? user.workshops.length === 1
        ? user.workshops[0]
        : null
      : { uuid: '1', name: 'Sulland Trondheim' }
  );
  const [comment, setComment] = useState('');
  const [deliveryType, setDeliveryType] = useState<'standard' | 'express'>('standard');
  const [loading, setLoading] = useState(false);
  const [formValidation, setFormValidation] = useState({ workshop: true });

  const validateForm = useCallback(() => {
    const validWorkshop = !!workshop;

    setFormValidation({ workshop: validWorkshop });

    return validWorkshop;
  }, [workshop]);

  const submitAction = useCallback((openDialog: () => void) => {
    if (!validateForm()) {
      return;
    }

    syncItems();

    openDialog();
  }, [validateForm, syncItems]);

  const confirmAction = useCallback((closeDialog: () => void) => {
    closeDialog();
    closeCart();

    setLoading(true);

    const orderItems = items.map(({ availableQuantity, ...rest }): OrderItem => ({ ...rest, comment: getComment(rest.code) }));

    createOrder({
      items: orderItems,
      workshopUuid: workshop!.uuid,
      comment,
      deliveryType,
      deliveryTotal: deliveryType === 'standard' ? 0 : 500,
      total,
    }).then((order) => {
      toast.success(t('orderCreated'));
      router.push(paths.afterSales.spareParts.order, { state: order });
    }).catch((error) => {
      toast.error(`${t('issueCreatingOrder')} - ${error.message}`)
    }).finally(() => {
      setLoading(false);
      // TODO: Remove this after create order API is implemented
      router.push(paths.afterSales.spareParts.order, { state: DATA.afterSales.sparePartOrder });
    });
  }, [
    items,
    getComment,
    workshop,
    comment,
    deliveryType,
    total,
    t,
    router,
    closeCart,
  ]);

  useEffect(() => {
    setItems();
  }, [setItems]);

  return (
    <>
      {
        loading &&
        <LoadingScreen portal />
      }

      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={openCart}
      >
        <Badge badgeContent={items.length} color="error">
          <SvgIcon sx={{ color: 'var(--layout-label-closed-color)' }}>
            <path opacity="0.32"
                  d="M3.41016 2C2.71981 2 2.16016 2.55964 2.16016 3.25C2.16016 3.94036 2.71981 4.5 3.41016 4.5H4.25251C4.48986 4.5 4.69436 4.66593 4.74281 4.89825C4.96591 5.96765 5.59766 8.95895 6.10461 11C6.73135 13.5235 7.29935 15.3419 7.6574 16.4028C8.09928 16.1466 8.61259 15.9999 9.16016 15.9999C10.446 15.9999 11.5429 16.8088 11.9697 17.9456C12.7178 17.979 13.5912 18 14.6046 18C15.462 18 16.2042 17.985 16.8452 17.9601C17.2681 16.8157 18.3689 15.9999 19.6602 15.9999C20.2143 15.9999 20.7334 16.1501 21.1789 16.4121C21.5323 15.5225 22.0073 14.033 22.4102 11.75C22.6992 10.1122 22.8768 8.88275 22.986 7.9902C23.1201 6.8938 22.2647 6 21.1602 6H7.66016L7.03891 3.51493C6.81631 2.62459 6.01636 2 5.09861 2H3.41016Z"
            />
            <path
              d="M17.5505 10.5941C17.6091 10.0083 17.1491 9.5 16.5604 9.5C16.0492 9.5 15.6212 9.88735 15.5703 10.3961L15.2694 13.4059C15.2108 13.9917 15.6708 14.5 16.2595 14.5C16.7707 14.5 17.1987 14.1126 17.2496 13.6039L17.5505 10.5941Z"
            />
            <path
              d="M12.7496 10.3961C12.6987 9.88735 12.2707 9.5 11.7595 9.5C11.1708 9.5 10.7108 10.0083 10.7694 10.5941L11.0703 13.6039C11.1212 14.1126 11.5492 14.5 12.0604 14.5C12.6491 14.5 13.1091 13.9917 13.0505 13.4059L12.7496 10.3961Z"
            />
            <path
              d="M16.6602 19C16.6602 20.6569 18.0033 22 19.6602 22C21.317 22 22.6602 20.6569 22.6602 19C22.6602 17.3432 21.317 16 19.6602 16C18.0033 16 16.6602 17.3432 16.6602 19Z"
            />
            <path
              d="M6.16016 19C6.16016 20.6569 7.50331 22 9.16016 22C10.817 22 12.1602 20.6569 12.1602 19C12.1602 17.3432 10.817 16 9.16016 16C7.50331 16 6.16016 17.3432 6.16016 19Z"
            />
          </SvgIcon>
        </Badge>
      </IconButton>

      <Drawer
        open={open}
        onClose={closeCart}
        anchor="right"
        PaperProps={{ sx: { width: 1, maxWidth: 420 } }}
      >
        <Stack
          sx={{
            padding: 3,
            boxShadow: 'inset 0 -1px 0 0 rgba(var(--palette-grey-500Channel) / 0.2)'
          }}
        >
          <Typography variant="h6">
            {t('cart')}
          </Typography>
        </Stack>

        <Stack
          sx={{
            height: 1,
            maxHeight: 620,
          }}
        >
          {
            pending &&
            <LoadingScreen />
          }

          {
            !pending &&
            <Scrollbar>
              <Box
                component="ul"
                sx={{
                  padding: 3,
                  gap: 3,
                }}
              >
                {items?.map((item) => (
                  <CartItem
                    key={item.code}
                    cartItem={item}
                  />
                ))}
              </Box>
            </Scrollbar>
          }
        </Stack>

        <Stack sx={{ mx: 3, borderBottom: '1px dashed rgba(var(--palette-grey-500Channel) / 0.2)'}} />

        <Stack sx={{ padding: 3, gap: 3 }}>
          <Autocomplete
            inputProps={{
              label: t('deliveryLocation'),
              error: !formValidation.workshop,
              errorText: t('pleaseSelectDeliveryLocation'),
            }}
            value={workshop}
            getOptionLabel={(option) => option?.name ?? ''}
            options={user?.workshops ?? [{ uuid: '1', name: 'Sulland Trondheim' }]}
            onChange={(event: any, newValue: Workshop | null) => setWorkshop(newValue)}
          />

          <TextField
            multiline
            rows={2}
            label={t('comments')}
            sx={{ width: 1 }}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Stack>

        <Stack sx={{ mx: 3, borderBottom: '1px dashed rgba(var(--palette-grey-500Channel) / 0.2)'}} />

        <Stack sx={{ py: 4, px: 3 }}>
          <FormControl sx={{ gap: 3 }}>
            <RadioGroup
              value={deliveryType}
              onChange={(e) => setDeliveryType(e.target.value as 'standard' | 'express')}
            >
              <FormControlLabel
                value="standard"
                control={<Radio color="default" />}
                label={t(`standardDelivery`)}
                labelPlacement="end"
              />

              <FormControlLabel
                value="express"
                control={<Radio color="default" />}
                label={`${t('expressDelivery')} - ${t('extraChargesMayApply')}`}
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </Stack>

        <Stack sx={{ mx: 3, borderBottom: '1px dashed rgba(var(--palette-grey-500Channel) / 0.2)'}} />

        <Stack sx={{ padding: 3, gap: 3 }}>
          <Stack sx={{ flexDirection: 'row' }}>
            <Typography
              variant="subtitle1"
              sx={{ width: '50%' }}
            >
              {t('total')}
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{ width: '50%', textAlign: 'right', color: 'var(--palette-primary-main)' }}
            >
              {total}
            </Typography>
          </Stack>

          <ButtonWithConfirmation
            buttonProps={{
              variant: 'contained',
              size: 'large',
              disabled: items.length < 1,
              onClick: submitAction,
            }}
            dialogProps={{
              title: t('confirmOrder?'),
              actions: {
                element: (closeDialog) => <>
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={() => confirmAction(closeDialog)}
                    disabled={items.length < 1 || pending}
                    sx={{ width: 1 }}
                  >
                    {t('confirm')}
                  </Button>

                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={closeDialog}
                    sx={{ width: 1 }}
                  >
                    {t('cancel')}
                  </Button>
                </>,
                sx: { padding: 0, gap: 1.5 },
              },
              sx: { minWidth: 360, py: 3, px: 6.75, gap: 8.75, textAlign: 'center' },
            }}
          >
            {t('confirmOrder')}
          </ButtonWithConfirmation>
        </Stack>
      </Drawer>
    </>
  );
}
