import { Navigate } from 'src/routes/components';

import { useHasAccess } from '../hooks';

// ----------------------------------------------------------------------

type Props = {
  requiredAccess: string | string[],
  operator?: 'or' | 'and' | 'any',
  navigateTo: string,
  children: React.ReactNode,
};

export function RoleRouteGuard({ requiredAccess, operator = 'and', navigateTo, children }: Props) {
  const { hasAccess } = useHasAccess(requiredAccess, operator);

  if (!hasAccess) {
    return <Navigate to={navigateTo} />;
  }

  return <>{children}</>;
}
