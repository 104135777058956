import { useContext } from 'react';

import { ConfigContext } from 'src/components/config-injector';

import { AuthGuard } from './auth-guard';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode,
};

export function AuthGuardWrapper({ children }: Props) {
  const { carxo: { frontend: { auth: { skip } } } } = useContext(ConfigContext);

  if (skip) {
    return <>{children}</>;
  }

  return <AuthGuard>{children}</AuthGuard>;
}
