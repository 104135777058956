import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from 'src/locales/langs/en/translation.json';
import no from 'src/locales/langs/no/translation.json';
import sv from 'src/locales/langs/sv/translation.json';
import fi from 'src/locales/langs/fi/translation.json';
import de from 'src/locales/langs/de/translation.json';
import zh from 'src/locales/langs/zh/translation.json';

const resources = {
  en: {
    translation: en,
  },
  no: {
    translation: no,
  },
  sv: {
    translation: sv,
  },
  fi: {
    translation: fi,
  },
  de: {
    translation: de,
  },
  zh: {
    translation: zh,
  },
};

const availableLanguages = Object.keys(resources);
const preferredLanguage = (navigator.languages ? navigator.languages[0] : navigator.language).slice(0, 2);
const fallbackLanguage = availableLanguages.includes(preferredLanguage) ? preferredLanguage : 'en';

i18n.use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('preferred-language') ?? fallbackLanguage,
    fallbackLng: 'en',
    debug: false,
  });

export default i18n;
