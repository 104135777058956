import ReactDOM from 'react-dom/client';
import { Suspense, StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './i18n';
import App from './app';
import { ConfigInjector } from './components/config-injector';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 60000,
    },
  },
});

root.render(
  <StrictMode>
    <ConfigInjector>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <Suspense>
            <App />
          </Suspense>
        </QueryClientProvider>
      </HelmetProvider>
    </ConfigInjector>
  </StrictMode>
);
