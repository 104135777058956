import type { ClaimStatus } from 'src/types/after-sales/claims';

export const DATA = {
  // VEHICLE CARD


  vehicleCard: {
    // GLOBAL SEARCH


    getGlobalSearchResults: [
      {
        uuid: '1',
        value: 'LDP95C962NE90131276',
        type: 'VIN',
      },
      {
        uuid: '2',
        value: 'DP70231',
        type: 'Registration Number',
      },
    ],


    // VEHICLE CARD VIEW
    getVehicleData: {
      uuid: '1',
      make: 'Voyah',
      model: 'Free',
      modification: {
        name: 'Free',
      },
      options: {
        INTERIOR: { name: 'Snow white' },
        EXTERIOR: { name: 'Dark blue' },
      },
      year: 2023,
      registrationNumber: 'DP70231',
      initialRegistrationDate: '2023-03-20',
      standardWarrantyEndDate: '2026-02-11',
      technicalCampaigns: [
        {
          uuid: '1',
          number: 'TZ240300',
          description: 'DCDC not charging under extreme low temperature',
          damageCode: 'N/A',
          status: 'open',
          serviceHistory: null,
        },
        {
          uuid: '2',
          number: 'TZ240301',
          description: 'OBC update due to unable to release charging gun',
          damageCode: 'N/A',
          status: 'closed',
          serviceHistory: {
            closedAt: '2024-07-13',
            mileage: 27500,
            workshop: {
              uuid: '123',
              name: 'Sulland Gruppen',
            },
            orderNumber: '1234',
          },
        },
      ],
      claims: [
        {
          uuid: 'CL-0000077',
          externalId: null,
          workshop: {
            name: 'Sulland Gruppen',
          },
          createdAt: '2023-04-21',
          updatedAt: '2023-04-21',
          status: 'draft' as ClaimStatus,
          serviceHistory: null,
        },
        {
          uuid: 'CL-0000078',
          externalId: null,
          workshop: {
            name: 'Sulland Gruppen',
          },
          createdAt: '2023-05-24',
          updatedAt: '2023-05-26',
          status: 'submitted' as ClaimStatus,
          serviceHistory: {
            closedAt: '2024-06-22',
            mileage: 23450,
            workshop: {
              uuid: '123',
              name: 'Sulland Gruppen',
            },
            orderNumber: '2345',
          },
        }
      ],
      serviceHistory: [
        {
          uuid: '1',
          closedAt: '2023-08-22',
          mileage: 20000,
          workshop: {
            uuid: '123',
            name: 'Sulland Gruppen',
          },
          orderNumber: 'Order-241',
          interval: {
            uuid: '1',
            name: 'PDI',
            items: [],
          }
        },
        {
          uuid: '2',
          closedAt: '2023-08-22',
          mileage: 20000,
          workshop: {
            uuid: '123',
            name: 'Sulland Gruppen',
          },
          orderNumber: 'Order-242',
          interval: {
            uuid: '2',
            name: '1 year/20 000 km',
            items: [
              {
                uuid: '1',
                name: 'Replaced Coolant',
              },
              {
                uuid: '3',
                name: 'Replaced A/C filter',
              },
              {
                uuid: '5',
                name: 'Replaced rear drive unit oil',
              },
            ],
          },
        }
      ],
      serviceIntervals: [
        {
          uuid: '1',
          name: 'PDI',
          items: [],
        },
        {
          uuid: '2',
          name: '1 year/20 000 km',
          items: [
            {
              uuid: '1',
              name: 'Replaced Coolant',
            },
            {
              uuid: '2',
              name: 'Replaced brake fluid',
            },
            {
              uuid: '3',
              name: 'Replaced A/C filter',
            },
            {
              uuid: '4',
              name: 'Replaced front drive unit oil',
            },
            {
              uuid: '5',
              name: 'Replaced rear drive unit oil',
            },
            {
              uuid: '6',
              name: 'Front brake service',
            },
            {
              uuid: '7',
              name: 'Rear brake service',
            },
            {
              uuid: '8',
              name: 'Replaced front brake pads',
            },
            {
              uuid: '9',
              name: 'Replaced rear brake pads',
            },
            {
              uuid: '10',
              name: 'Replaced front brake discs',
            },
            {
              uuid: '11',
              name: 'Replaced rear brake discs',
            },
          ],
        },
        {
          uuid: '3',
          name: '2 year/40 000 km',
          items: [
            {
              uuid: '12',
              name: 'Replaced Coolant',
            },
            {
              uuid: '13',
              name: 'Replaced brake fluid',
            },
            {
              uuid: '14',
              name: 'Replaced A/C filter',
            },
            {
              uuid: '15',
              name: 'Replaced front drive unit oil',
            },
            {
              uuid: '16',
              name: 'Replaced rear drive unit oil',
            },
            {
              uuid: '17',
              name: 'Front brake service',
            },
            {
              uuid: '18',
              name: 'Rear brake service',
            },
            {
              uuid: '19',
              name: 'Replaced front brake pads',
            },
            {
              uuid: '20',
              name: 'Replaced rear brake pads',
            },
            {
              uuid: '21',
              name: 'Replaced front brake discs',
            },
            {
              uuid: '22',
              name: 'Replaced rear brake discs',
            },
          ],
        }
      ],
      vin: 'LDP95C962NE90131276',
      city: 'Stockholm',
      countryCode: 'SE',
      attachment: {
        uuid: '1',
        name: 'document.pdf',
        size: 5600000,
        createdAt: '2024-07-21',
      },
      pdiTemplate: {
        uuid: '2',
        name: 'PDI.pdf',
      },
    }
  },
  // SALES


  sales: {
    // DASHBOARD


    dashboard: {},
    // NEWS


    news: [],
    // INVENTORY


    getInventory: [
      {
        uuid: '11',
        city: 'Wallhamn',
        countryCode: 'SE',
        make: 'Voyah',
        model: 'Free',
        year: 2023,
        modification: { name: 'Free' },
        options: {
          INTERIOR: { name: 'Snow white' },
          EXTERIOR: { name: 'Dark blue' },
        },
      },
      {
        uuid: '12',
        city: 'Wallhamn',
        countryCode: 'SE',
        make: 'Voyah',
        model: 'Dream',
        year: 2023,
        modification: { name: 'Dream' },
        options: {
          INTERIOR: { name: 'Snow white' },
          EXTERIOR: { name: 'Dark blue' },
        },
      }
    ],
    // ORDER REQUESTS


    getOrderRequests: {
      data: [
        {
          uuid: 'T0000130',
          vehicle: {
            make: 'Voyah',
            model: 'Free',
          },
          createdAt: '2024-08-21',
          reservedUntil: '2024-09-11',
          workshop: {
            name: 'Sulland Gruppen',
          },
          company: {
            name: 'Sulland Gruppen',
          },
          documentReference: 'John Doe'
        },
        {
          uuid: 'T0000131',
          vehicle: {
            make: 'Voyah',
            model: 'Dream',
          },
          createdAt: '2024-08-21',
          reservedUntil: null,
          workshop: {
            name: 'Sulland Gruppen',
          },
          company: {
            name: 'Sulland Gruppen',
          },
          documentReference: 'Jane Doe'
        }
      ],
      total: 2,
    },
    // VIEW ORDER REQUEST


    getOrderRequestData: {
      uuid: 'T0000130',
      createdAt: '2024-08-21',
      reservedUntil: '2024-09-11',
      workshop: {
        address: 'Some random address',
      },
      company: {
        name: 'Sulland Gruppen',
      },
      documentReference: 'John Doe',
      createdBy: {
        name: 'Martins P',
      },
      vehicle: {
        make: 'Voyah',
        model: 'Free',
        year: 2023,
        vin: 'LDP95C962NE90123987',
        modification: { name: 'Free' },
        options: {
          INTERIOR: { name: 'Snow white' },
          EXTERIOR: { name: 'Dark blue' },
        },
      },
      total: 0,
    },
    // NEW ORDER REQUEST


    newOrderRequest: {
      // VEHICLE OPTIONS (MAKE/MODEL)


      getConfiguratorVehicles: [
        {
          uuid: '1',
          make: 'Voyah',
          model: 'Free',
        },
        {
          uuid: '2',
          make: 'Voyah',
          model: 'Dream',
        },
        {
          uuid: '3',
          make: 'Voyah',
          model: 'Courage',
        },
        {
          uuid: '4',
          make: 'Voyah',
          model: 'Passion',
        },
        {
          uuid: '5',
          make: 'DongFeng',
          model: 'Box',
        },
        {
          uuid: '6',
          make: 'M-Hero',
          model: 'M One',
        },
      ],
      // MODIFICATIONS (FOR CONFIGURATOR VEHICLE)


      getModifications: [
        {
          uuid: '1',
          name: 'Base',
          price: 655000,
        },
        {
          uuid: '2',
          name: 'Black line',
          price: 745000,
        },
        {
          uuid: '3',
          name: 'Sport Premium',
          price: 825000,
        }
      ],
      // OPTIONS (FOR MODIFICATION)


      getOptions: [
        {
          uuid: '1',
          type: 'exterior',
          name: 'Red',
          price: 10000,
        },
        {
          uuid: '2',
          type: 'exterior',
          name: 'Black',
          price: 15000,
        },
        {
          uuid: '3',
          type: 'exterior',
          name: 'Blue',
          price: 12500,
        },
        {
          uuid: '4',
          type: 'interior',
          name: 'Brown',
          price: 25000,
        },
        {
          uuid: '5',
          type: 'interior',
          name: 'White',
          price: 32000,
        },
        {
          uuid: '6',
          type: 'interior',
          name: 'Black',
          price: 12000,
        },
      ],
    },
    // ORDERS


    getOrders: {
      data: [
        {
          uuid: '1',
          externalId: 'order-uuid-1',
          vehicle: {
            make: 'Voyah',
            model: 'Free',
            vin: '',
            year: 2024,
            modification: { name: 'Free' },
            options: {
              INTERIOR: { uuid: '1', name: 'Snow white' },
              EXTERIOR: { uuid: '2', name: 'Dark blue' },
            },
          },
          workshop: {
            address: 'Some random street',
          },
          company: {
            name: 'Sulland Gruppen',
          },
          orderType: 'booking' as 'booking',
          createdAt: '2024-08-21',
          deliveryDate: '2025-02-21',
          totalAmount: 700000,
          status: 'pending' as 'pending',
        },
        {
          uuid: '2',
          externalId: 'order-uuid-2',
          vehicle: {
            make: 'Voyah',
            model: 'Free',
            vin: 'LDP95C962NE90123987',
            year: 2023,
            modification: { name: 'Free' },
            options: {
              INTERIOR: { uuid: '1', name: 'Snow white' },
              EXTERIOR: { uuid: '2', name: 'Dark blue' },
            },
          },
          workshop: {
            address: 'Some random street',
          },
          company: {
            name: 'Sulland Gruppen',
          },
          orderType: 'reservation' as 'reservation',
          createdAt: '2024-08-22',
          deliveryDate: '2024-08-25',
          totalAmount: 0,
          status: 'completed' as 'completed',
        },
      ],
      total: 2,
    },
    // SALES REPORTING


    salesReporting: {
      getSalesReportAggregates: () => [
        {
          company: {
            uuid: '1',
            name: 'Sulland Gruppen',
          },
          months: [
            {
              uuid: '1',
              year: 2024,
              month: 'january' as 'january',
              reportingDate: '2024-01-05',
              noRegisteredVehiclesLastMonth: 2,
              forecastVehiclesRegisteredThisMonth: 2,
              vehicles: [
                {
                  model: 'Box',
                  noTestDrivesLastMonth: 2,
                  noDemoVehicles: 2,
                },
                {
                  model: 'H37',
                  noTestDrivesLastMonth: 0,
                  noDemoVehicles: 2,
                },
                {
                  model: 'Free',
                  noTestDrivesLastMonth: 1,
                  noDemoVehicles: 2,
                },
                {
                  model: 'Dream',
                  noTestDrivesLastMonth: 3,
                  noDemoVehicles: 2,
                },
                {
                  model: 'M-One',
                  noTestDrivesLastMonth: 4,
                  noDemoVehicles: 2,
                },
              ],
              spendingLastMonth: 54000,
              forecastSpending: 37200,
              noVehiclesInStock: 2,
              totalValueVehiclesInStock: 1560230,
              noPurchasedVehiclesFromEW: 2,
              comments: 'We sold 5 vehicles more than planned and we spent 30 000 NOK more than planned',
            },
            {
              uuid: '2',
              year: 2024,
              month: 'february' as 'february',
              reportingDate: '2024-02-07',
              noRegisteredVehiclesLastMonth: 3,
              forecastVehiclesRegisteredThisMonth: 5,
              vehicles: [
                {
                  model: 'Box',
                  noTestDrivesLastMonth: 3,
                  noDemoVehicles: 5,
                },
                {
                  model: 'H37',
                  noTestDrivesLastMonth: 2,
                  noDemoVehicles: 5,
                },
                {
                  model: 'Free',
                  noTestDrivesLastMonth: 3,
                  noDemoVehicles: 5,
                },
                {
                  model: 'Dream',
                  noTestDrivesLastMonth: 1,
                  noDemoVehicles: 5,
                },
                {
                  model: 'M-One',
                  noTestDrivesLastMonth: 1,
                  noDemoVehicles: 5,
                },
              ],
              spendingLastMonth: 35000,
              forecastSpending: 43000,
              noVehiclesInStock: 5,
              totalValueVehiclesInStock: 2843200,
              noPurchasedVehiclesFromEW: 5,
              comments: 'We sold 5 vehicles more than planned and we spent 30 000 NOK more than planned',
            },
            {
              uuid: '3',
              year: 2024,
              month: 'march' as 'march',
              reportingDate: '2024-03-08',
              noRegisteredVehiclesLastMonth: 5,
              forecastVehiclesRegisteredThisMonth: 5,
              vehicles: [
                {
                  model: 'Box',
                  noTestDrivesLastMonth: 2,
                  noDemoVehicles: 5,
                },
                {
                  model: 'H37',
                  noTestDrivesLastMonth: 3,
                  noDemoVehicles: 5,
                },
                {
                  model: 'Free',
                  noTestDrivesLastMonth: 1,
                  noDemoVehicles: 5,
                },
                {
                  model: 'Dream',
                  noTestDrivesLastMonth: 1,
                  noDemoVehicles: 5,
                },
                {
                  model: 'M-One',
                  noTestDrivesLastMonth: 4,
                  noDemoVehicles: 5,
                },
              ],
              spendingLastMonth: 72000,
              forecastSpending: 54000,
              noVehiclesInStock: 5,
              totalValueVehiclesInStock: 2843200,
              noPurchasedVehiclesFromEW: 5,
              comments: 'We sold 5 vehicles more than planned and we spent 30 000 NOK more than planned',
            },
            {
              uuid: '4',
              year: 2024,
              month: 'april' as 'april',
              reportingDate: '2024-04-06',
              noRegisteredVehiclesLastMonth: 2,
              forecastVehiclesRegisteredThisMonth: 5,
              vehicles: [
                {
                  model: 'Box',
                  noTestDrivesLastMonth: 2,
                  noDemoVehicles: 5,
                },
                {
                  model: 'H37',
                  noTestDrivesLastMonth: 3,
                  noDemoVehicles: 5,
                },
                {
                  model: 'Free',
                  noTestDrivesLastMonth: 1,
                  noDemoVehicles: 5,
                },
                {
                  model: 'Dream',
                  noTestDrivesLastMonth: 1,
                  noDemoVehicles: 5,
                },
                {
                  model: 'M-One',
                  noTestDrivesLastMonth: 4,
                  noDemoVehicles: 5,
                },
              ],
              spendingLastMonth: 72000,
              forecastSpending: 54000,
              noVehiclesInStock: 5,
              totalValueVehiclesInStock: 2843200,
              noPurchasedVehiclesFromEW: 5,
              comments: 'We sold 5 vehicles more than planned and we spent 30 000 NOK more than planned',
            },
          ],
          summary: {
            noRegisteredVehiclesLastMonth: 12,
            forecastVehiclesRegisteredThisMonth: 17,
            vehicles: [
              {
                model: 'Box',
                noTestDrivesLastMonth: 9,
              },
              {
                model: 'H37',
                noTestDrivesLastMonth: 8,
              },
              {
                model: 'Free',
                noTestDrivesLastMonth: 6,
              },
              {
                model: 'Dream',
                noTestDrivesLastMonth: 6,
              },
              {
                model: 'M-One',
                noTestDrivesLastMonth: 13,
              },
            ],
            spendingLastMonth: 233000,
            forecastSpending: 188200,
          },
        },
        {
          company: {
            uuid: '2',
            name: 'Sulland Gruppen 2',
          },
          months: [
            {
              uuid: '5',
              year: 2024,
              month: 'january' as 'january',
              reportingDate: '2024-01-05',
              noRegisteredVehiclesLastMonth: 2,
              forecastVehiclesRegisteredThisMonth: 2,
              vehicles: [
                {
                  model: 'Box',
                  noTestDrivesLastMonth: 2,
                  noDemoVehicles: 2,
                },
                {
                  model: 'H37',
                  noTestDrivesLastMonth: 0,
                  noDemoVehicles: 2,
                },
                {
                  model: 'Free',
                  noTestDrivesLastMonth: 1,
                  noDemoVehicles: 2,
                },
                {
                  model: 'Dream',
                  noTestDrivesLastMonth: 3,
                  noDemoVehicles: 2,
                },
                {
                  model: 'M-One',
                  noTestDrivesLastMonth: 4,
                  noDemoVehicles: 2,
                },
              ],
              spendingLastMonth: 54000,
              forecastSpending: 37200,
              noVehiclesInStock: 2,
              totalValueVehiclesInStock: 1560230,
              noPurchasedVehiclesFromEW: 2,
              comments: 'We sold 5 vehicles more than planned and we spent 30 000 NOK more than planned',
            },
            {
              uuid: '6',
              year: 2024,
              month: 'february' as 'february',
              reportingDate: '2024-02-07',
              noRegisteredVehiclesLastMonth: 3,
              forecastVehiclesRegisteredThisMonth: 5,
              vehicles: [
                {
                  model: 'Box',
                  noTestDrivesLastMonth: 3,
                  noDemoVehicles: 5,
                },
                {
                  model: 'H37',
                  noTestDrivesLastMonth: 2,
                  noDemoVehicles: 5,
                },
                {
                  model: 'Free',
                  noTestDrivesLastMonth: 3,
                  noDemoVehicles: 5,
                },
                {
                  model: 'Dream',
                  noTestDrivesLastMonth: 1,
                  noDemoVehicles: 5,
                },
                {
                  model: 'M-One',
                  noTestDrivesLastMonth: 1,
                  noDemoVehicles: 5,
                },
              ],
              spendingLastMonth: 35000,
              forecastSpending: 43000,
              noVehiclesInStock: 5,
              totalValueVehiclesInStock: 2843200,
              noPurchasedVehiclesFromEW: 5,
              comments: 'We sold 5 vehicles more than planned and we spent 30 000 NOK more than planned',
            },
            {
              uuid: '7',
              year: 2024,
              month: 'march' as 'march',
              reportingDate: '2024-03-08',
              noRegisteredVehiclesLastMonth: 5,
              forecastVehiclesRegisteredThisMonth: 5,
              vehicles: [
                {
                  model: 'Box',
                  noTestDrivesLastMonth: 2,
                  noDemoVehicles: 5,
                },
                {
                  model: 'H37',
                  noTestDrivesLastMonth: 3,
                  noDemoVehicles: 5,
                },
                {
                  model: 'Free',
                  noTestDrivesLastMonth: 1,
                  noDemoVehicles: 5,
                },
                {
                  model: 'Dream',
                  noTestDrivesLastMonth: 1,
                  noDemoVehicles: 5,
                },
                {
                  model: 'M-One',
                  noTestDrivesLastMonth: 4,
                  noDemoVehicles: 5,
                },
              ],
              spendingLastMonth: 72000,
              forecastSpending: 54000,
              noVehiclesInStock: 5,
              totalValueVehiclesInStock: 2843200,
              noPurchasedVehiclesFromEW: 5,
              comments: 'We sold 5 vehicles more than planned and we spent 30 000 NOK more than planned',
            },
            {
              uuid: '8',
              year: 2024,
              month: 'april' as 'april',
              reportingDate: '2024-04-06',
              noRegisteredVehiclesLastMonth: 2,
              forecastVehiclesRegisteredThisMonth: 5,
              vehicles: [
                {
                  model: 'Box',
                  noTestDrivesLastMonth: 2,
                  noDemoVehicles: 5,
                },
                {
                  model: 'H37',
                  noTestDrivesLastMonth: 3,
                  noDemoVehicles: 5,
                },
                {
                  model: 'Free',
                  noTestDrivesLastMonth: 1,
                  noDemoVehicles: 5,
                },
                {
                  model: 'Dream',
                  noTestDrivesLastMonth: 1,
                  noDemoVehicles: 5,
                },
                {
                  model: 'M-One',
                  noTestDrivesLastMonth: 4,
                  noDemoVehicles: 5,
                },
              ],
              spendingLastMonth: 72000,
              forecastSpending: 54000,
              noVehiclesInStock: 5,
              totalValueVehiclesInStock: 2843200,
              noPurchasedVehiclesFromEW: 5,
              comments: 'We sold 5 vehicles more than planned and we spent 30 000 NOK more than planned',
            },
          ],
          summary: {
            noRegisteredVehiclesLastMonth: 12,
            forecastVehiclesRegisteredThisMonth: 17,
            vehicles: [
              {
                model: 'Box',
                noTestDrivesLastMonth: 9,
              },
              {
                model: 'H37',
                noTestDrivesLastMonth: 8,
              },
              {
                model: 'Free',
                noTestDrivesLastMonth: 6,
              },
              {
                model: 'Dream',
                noTestDrivesLastMonth: 6,
              },
              {
                model: 'M-One',
                noTestDrivesLastMonth: 13,
              },
            ],
            spendingLastMonth: 233000,
            forecastSpending: 188200,
          },
        },
      ],
      getSalesReportData: {
        uuid: '1',
        year: 2024,
        month: 'january' as 'january',
        reportingDate: '2024-01-05',
        noRegisteredVehiclesLastMonth: 2,
        forecastVehiclesRegisteredThisMonth: 2,
        vehicles: [
          {
            model: 'Box',
            noTestDrivesLastMonth: 2,
            noDemoVehicles: 2,
          },
          {
            model: 'H37',
            noTestDrivesLastMonth: 0,
            noDemoVehicles: 2,
          },
          {
            model: 'Free',
            noTestDrivesLastMonth: 1,
            noDemoVehicles: 2,
          },
          {
            model: 'Dream',
            noTestDrivesLastMonth: 3,
            noDemoVehicles: 2,
          },
          {
            model: 'M-One',
            noTestDrivesLastMonth: 4,
            noDemoVehicles: 2,
          },
        ],
        spendingLastMonth: 54000,
        forecastSpending: 37200,
        noVehiclesInStock: 2,
        totalValueVehiclesInStock: 1560230,
        noPurchasedVehiclesFromEW: 2,
        comments: 'We sold 5 vehicles more than planned and we spent 30 000 NOK more than planned',
      },
    },
    // PRICE LIST


    getPriceList: [
      {
        type: 'file' as 'file',
        name: 'Voyah-Free-2023.pptx',
        updatedAt: '2023-08-29',
        size: 19200000,
        path: 'https://google.com',
      },
      {
        type: 'file' as 'file',
        name: 'Voyah-Dream-2023.pptx',
        updatedAt: '2023-08-29',
        size: 19200000,
        path: 'https://google.com',
      },
      {
        type: 'file' as 'file',
        name: 'Voyah-Free-2024.pptx',
        updatedAt: '2024-08-29',
        size: 21800000,
        path: 'https://google.com',
      },
      {
        type: 'file' as 'file',
        name: 'Voyah-Dream-2024.pptx',
        updatedAt: '2024-08-29',
        size: 21800000,
        path: 'https://google.com',
      },
      {
        type: 'file' as 'file',
        name: 'Voyah-Courage-2024.pptx',
        updatedAt: '2024-08-29',
        size: 21800000,
        path: 'https://google.com',
      },
      {
        type: 'file' as 'file',
        name: 'Voyah-Passion-2024.pptx',
        updatedAt: '2024-08-29',
        size: 21800000,
        path: 'https://google.com',
      }
    ],
    // VIEW ORDER


    getOrderData: {
      uuid: '1',
      externalId: 'external-1',
      createdAt: '2024-08-29',
      workshop: {
        address: 'Some random street',
      },
      company: {
        name: 'Sulland Gruppen',
      },
      deliveryDate: '2024-08-29',
      orderType: 'booking' as 'booking',
      documentReference: 'dealer reference 1',
      createdBy: {
        name: 'Martins P'
      },
      status: 'completed' as 'completed',
      vehicle: {
        make: 'Voyah',
        model: 'Free',
        vin: '',
        year: 2023,
        modification: { name: 'Free' },
        options: {
          INTERIOR: { uuid: '1', name: 'Snow white' },
          EXTERIOR: { uuid: '2', name: 'Dark blue' },
        },
      },
      total: 727715,
    },
  },
  // AFTER SALES


  afterSales: {
    // DASHBOARD


    dashboard: {},
    // CLAIMS


    getClaims: [
      {
        uuid: 'CL-0000103',
        externalId: null,
        vin: 'LDP95C962NE90131276',
        make: 'Voyah',
        model: 'Free',
        workshop: {
          name: 'Sulland Gruppen',
        },
        company: {
          name: 'Sulland Gruppen',
        },
        createdAt: '2024-08-22',
        updatedAt: '2024-08-22 17:30',
        status: 'draft',
      },
      {
        uuid: 'CL-0000104',
        externalId: null,
        vin: 'LDP95C962NE90131276',
        make: 'Voyah',
        model: 'Free',
        workshop: {
          name: 'Sulland Gruppen',
        },
        company: {
          name: 'Sulland Gruppen',
        },
        createdAt: '2024-08-22',
        updatedAt: '2024-08-22 17:30',
        status: 'submitted',
      },
      {
        uuid: 'CL-0000105',
        externalId: null,
        vin: 'LDP95C962NE90131276',
        make: 'Voyah',
        model: 'Free',
        workshop: {
          name: 'Sulland Gruppen',
        },
        company: {
          name: 'Sulland Gruppen',
        },
        createdAt: '2024-08-22',
        updatedAt: '2024-08-22 17:30',
        status: 'in review',
      },
      {
        uuid: 'CL-0000106',
        externalId: null,
        vin: 'LDP95C962NE90131276',
        make: 'Voyah',
        model: 'Free',
        workshop: {
          name: 'Sulland Gruppen',
        },
        company: {
          name: 'Sulland Gruppen',
        },
        createdAt: '2024-08-22',
        updatedAt: '2024-08-22 17:30',
        status: 'info needed',
      },
      {
        uuid: 'CL-0000107',
        externalId: null,
        vin: 'LDP95C962NE90131276',
        make: 'Voyah',
        model: 'Free',
        workshop: {
          name: 'Sulland Gruppen',
        },
        company: {
          name: 'Sulland Gruppen',
        },
        createdAt: '2024-08-22',
        updatedAt: '2024-08-22 17:30',
        status: 'accepted',
      },
    ],
    // VIEW CLAIM


    getClaimData: {
      uuid: 'CL-0000106',
      externalId: null,
      createdAt: '2024-08-22',
      repairDate: '2024-08-22',
      complaintDate: '2024-08-17',
      repeatRepair: false,
      inventoryVehicle: {
        uuid: '11',
        vin: 'LDP95C962NE90131276',
        make: 'Voyah',
        model: 'Free',
        modification: { name: 'Free' },
        options: {
          INTERIOR: { uuid: '1', name: 'Snow white' },
          EXTERIOR: { uuid: '2', name: 'Dark blue' },
        },
        registrationNumber: 'DP70231',
        warrantyEndDate: '2026-02-11',
        attachment: {
          uuid: '123',
        },
      },
      mileage: 26500,
      orderNumber: 'orderNo112233',
      orderIdType: 'meko' as 'meko',
      orderId: '445566',
      status: 'submitted' as ClaimStatus,
      updatedAt: '2024-08-22 12:30',
      infoNeededComment: 'The cause and failure analysis is not detailed enough',
      workshop: {
        uuid: '1',
        name: 'Sulland Gruppen',
      },
      company: {
        name: 'Sulland Gruppen',
      },
      createdBy: {
        name: 'Linards'
      },
      faultEntities: [
        { uuid: 'uuid-1', type: 'base' as 'base', name: 'Fault type 1' },
        { uuid: 'uuid-1', type: 'phenomenon' as 'phenomenon', name: 'Fault phenomenon 1' },
        { uuid: 'uuid-1', type: 'position' as 'position', name: 'Failure position 1' },
      ],
      complaint: 'This is the customer complaint',
      causeAnalysis: 'This is the cause analysis',
      failureAnalysis: 'This is the failure analysis',
      labors: [
        {
          uuid: '112',
          code: '112',
          description: 'This is some labor',
          operationTime: 3.7,
          laborRate: 70,
          lineAmount: 259,
        },
      ],
      spareParts: [
        {
          uuid: '112',
          code: '112',
          description: 'Spare part 1',
          price: 70,
          quantity: 1,
          lineAmount: 70,
        },
      ],
      surcharges: [
        {
          attachments: [
            { uuid: '1', name: 'workorder.pdf', size: 25200000, createdAt: '2024-08-07', path: '/attachments/claims/CL-0000106/workorder.pdf' }
          ],
          description: 'Paint job, changing color to red',
          amount: 2500,
        },
        {
          attachments: [
            { uuid: '2', name: 'restraining-order.pdf', size: 25200000, createdAt: '2024-08-07', path: '/attachments/claims/CL-0000106/restraining-order.pdf' }
          ],
          description: 'Adding external modifications',
          amount: 1200,
        }
      ],
      courtesyCar: {
        type: 'Small',
        duration: 2,
        amount: 600,
        attachments: [
          { uuid: '2', name: 'cardocs.pdf', size: 25200000, createdAt: '2024-08-07', path: '/attachments/claims/CL-0000106/cardocs.pdf' },
        ],
      },
      attachments: [
        { uuid: '3', name: 'claim-notes.pdf', size: 25200000, createdAt: '2024-08-07', path: '/attachments/claims/CL-0000106/claim-notes.pdf' }
      ],
    },
    // LABOR SEARCH


    getLaborSearchResults: [
      { uuid: '112', code: '112', description: 'This is some labor', operationTime: 3.7, laborRate: 70 },
      { uuid: '223', code: '223', description: 'This is some other labor', operationTime: 2.2, laborRate: 110 },
      { uuid: '334', code: '334', description: 'Fixing headlamps', operationTime: 7, laborRate: 150 },
      { uuid: '445', code: '445', description: 'Fixing roof cover', operationTime: 4.4, laborRate: 65 },
    ],
    // SPARE PART SEARCH


    getSparePartSearchResults: [
      { uuid: '112', code: '112', description: 'Spare part 1', price: 70 },
      { uuid: '223', code: '223', description: 'Spare part 2', price: 110 },
      { uuid: '334', code: '334', description: 'Spare part 3', price: 150 },
      { uuid: '445', code: '445', description: 'Spare part 4', price: 65 },
    ],
    // TECHNICAL CAMPAIGNS


    getTechnicalCampaigns: [
      {
        uuid: '1',
        company: {
          name: 'Sulland Gruppen',
        },
        number: 'TZ240300',
        description: 'DCDC not charging under extreme low temperature',
        damageCode: 'N/A',
      },
      {
        uuid: '2',
        company: {
          name: 'Sulland Gruppen',
        },
        number: 'TZ240301',
        description: 'Battery capped to 80%',
        damageCode: 'N/A',
      },
      {
        uuid: '3',
        company: {
          name: 'Sulland Gruppen',
        },
        number: 'TZ240302',
        description: 'Mud-guards crack under extreme low temperature',
        damageCode: 'N/A',
      },
    ],
    // VIEW TECHNICAL CAMPAIGN


    getTechnicalCampaignData: {
      uuid: '1',
      number: 'TZ240300',
      description: 'DCDC not charging under extreme low temperature',
      damageCode: '361901A',
      vehicles: [
        {
          uuid: '1',
          vin: 'LDP95C962NE90123987',
          make: 'Voyah',
          model: 'Free',
          modification: { name: 'Free' },
          year: 2023,
          status: 'open',
        },
        {
          uuid: '2',
          vin: 'LDP95C962NE90123988',
          make: 'Voyah',
          model: 'Free',
          modification: { name: 'Free' },
          year: 2023,
          status: 'open',
        },
        {
          uuid: '3',
          vin: 'LDP95C962NE90123989',
          make: 'Voyah',
          model: 'Free',
          modification: { name: 'Free' },
          year: 2023,
          status: 'closed',
        },
        {
          uuid: '4',
          vin: 'LDP95C962NE90123990',
          make: 'Voyah',
          model: 'Free',
          modification: { name: 'Free' },
          year: 2024,
          status: 'open',
        },
        {
          uuid: '5',
          vin: 'LDP95C962NE90123991',
          make: 'Voyah',
          model: 'Free',
          modification: { name: 'Free' },
          year: 2024,
          status: 'closed',
        }
      ],
      labors: [
        {
          code: '361901A',
          description: 'Replace EV communication controller',
          operationTime: 3.7,
          laborRate: 77,
          lineAmount: 284.9,
        }
      ],
      spareParts: [
        {
          code: '361901A',
          description: 'DC-DC converter',
          quantity: 1,
          price: 1000,
          lineAmount: 1000,
        }
      ],
      attachments: [
        {
          uuid: '1',
          name: 'cmp-TZ240300.pdf',
          size: 25200000,
          createdAt: '2024-08-07',
          path: '/attachments/technical-campaigns/cmp-TZ240300.pdf',
        },
        {
          uuid: '2',
          name: '361901A.pdf',
          size: 25200000,
          createdAt: '2024-08-07',
          path: '/attachments/technical-campaigns/361901A.pdf',
        }
      ],
      startDate: '2024-08-09',
      endDate: '2029-08-09',
    },
    // ORDERS


    getOrders: {
      data: [
        {
          uuid: '3',
          company: {
            name: 'Sulland Gruppen',
          },
          externalId: 'order-uuid-3',
          createdAt: '2024-08-23',
          deliveryDate: '2024-09-06',
          totalAmount: 42000,
          status: 'completed' as 'completed',
        },
        {
          uuid: '4',
          company: {
            name: 'Sulland Gruppen',
          },
          externalId: 'order-uuid-4',
          createdAt: '2024-09-15',
          deliveryDate: '2024-12-02',
          totalAmount: 69500,
          status: 'pending' as 'pending',
        },
      ],
      total: 2,
    },
    // SPARE PARTS


    getSpareParts: [
      {
        code: '570401002',
        description: 'Left baggage carrier',
        price: 2831.76,
        retailPrice: 3555.16,
        availableQuantity: 5,
      },
      {
        code: '570402002',
        description: 'Right baggage holder',
        price: 2831.76,
        retailPrice: 3555.16,
        availableQuantity: 2,
      },
      {
        code: '790308001',
        description: 'Bottom PS Antenna',
        price: 291.1,
        retailPrice: 365.16,
        availableQuantity: 0,
      },
      {
        code: '570403002',
        description: 'MP4 Audio host',
        price: 48458.76,
        retailPrice: 60837.16,
        availableQuantity: 4,
      },
      {
        code: '570404002',
        description: 'Roof mounting',
        price: 5711.22,
        retailPrice: 7243.12,
        availableQuantity: 4,
      },
    ],
    // VIEW ORDER


    getOrderData: {
      uuid: '1',
      externalId: 'external-1',
      createdAt: '2024-08-29',
      workshop: {
        address: 'Some random street',
      },
      company: {
        name: 'Sulland Gruppen',
      },
      deliveryDate: '2024-08-29',
      createdBy: {
        name: 'Martins P'
      },
      status: 'completed' as 'completed',
      items: [
        { code: '570401002', description: 'Left baggage carrier', lineAmount: 5663.52, quantity: 2, comment: 'Some comment' },
        { code: '570402002', description: 'Right baggage holder', lineAmount: 8495.28, quantity: 3, comment: '' }
      ],
      comment: 'Some general comment about the order',
      deliveryType: 'express' as 'express',
      deliveryTotal: 600,
      total: 7610,
    },
    // SPARE PART ORDER OVERVIEW (AFTER ORDERING)


    sparePartOrder: {
      uuid: '1234',
      items: [
        { code: '570401002', description: 'Left baggage carrier', lineAmount: 5663.52, quantity: 2, comment: 'Some comment' },
        { code: '570402002', description: 'Right baggage holder', lineAmount: 8495.28, quantity: 3, comment: '' }
      ],
      workshop: {
        name: 'Sulland Gruppen',
        address: 'Klingenberggata 7 0161 Oslo',
      },
      comment: 'Generic comment',
      deliveryType: 'express' as 'express',
      deliveryTotal: 500,
      total: 7610.32,
    },
  },
  // MARKETING


  marketing: {
    // DASHBOARD


    dashboard: {},
    // EVENTS


    getEvents: [
      {
        uuid: '1',
        company: {
          name: 'Sulland Gruppen',
        },
        eventTitle: 'Christmas Campaign',
        startDate: '2024-12-01',
        endDate: '2024-12-31',
        plannedTotalCost: 90000,
        eventType: 'in store',
        eventStatus: 'approved',
      },
      {
        uuid: '2',
        company: {
          name: 'Sulland Gruppen',
        },
        eventTitle: 'March Madness Campaign',
        startDate: '2025-03-01',
        endDate: '2025-03-31',
        plannedTotalCost: 120000,
        eventType: 'stand',
        eventStatus: 'waiting for approval',
      },
      {
        uuid: '3',
        company: {
          name: 'Sulland Gruppen',
        },
        eventTitle: 'Easter Campaign',
        startDate: '2025-04-01',
        endDate: '2025-04-31',
        plannedTotalCost: 65000,
        eventType: 'display',
        eventStatus: 'rejected',
      }
    ],
    // VIEW EVENT


    getEventData: {
      uuid: '1',
      company: {
        name: 'Sulland Gruppen',
      },
      eventType: 'in store',
      eventTitle: 'Christmas Campaign',
      startDate: '2024-12-01',
      endDate: '2024-12-31',
      description: 'Event for the Christmas Campaign',
      resultDescription: '',
      attachments: [
        {
          uuid: '3',
          name: 'christmas-2024.pdf',
          size: 25200000,
          createdAt: '2024-08-07',
          path: '/attachments/events/christmas-2024.pdf',
        },
        {
          uuid: '4',
          name: 'christmas-event-invoice.pdf',
          size: 25200000,
          createdAt: '2024-08-07',
          path: '/attachments/events/christmas-event-invoice.pdf',
        },
      ],
      plannedLeads: 200,
      plannedVisitors: 1000,
      plannedSales: 200000,
      plannedTotalCost: 90000,
      actualLeads: 0,
      actualVisitors: 0,
      actualSales: 0,
      actualTotalCost: 0,
      eventStatus: 'approved',
      resultStatus: 'waiting for approval',
    },
  },
  // NEWS (API WOULD RECEIVE 'type' property [sales/afterSales/marketing])


  getNews: [
    {
      type: 'sales' as 'sales',
      title: 'EV Sales Surge : A look at the latest global market trends',
      description: 'Analysis of recent sales data, highlighting key markets and growth trends. Include insights on what’s driving the sales surge in various regions.',
      text: '<h1>First title</h1><p>Some text here, lorem ipsum etc. etc.</p><img src="https://img.cnevpost.com/2021/06/28084155/a434560bcfda18cdd66dcdcfcb454d01.jpg"/><p>Some more text here</p>',
      createdAt: '2022-08-12',
      image: 'https://blog.evbox.com/hs-fs/hubfs/electric-car-power-charging.jpg?width=1920&name=electric-car-power-charging.jpg',
      author: 'Espen Holje',
    },
    {
      type: 'sales' as 'sales',
      title: 'EV Sales Surge : A look at the latest global market trends',
      description: 'Analysis of recent sales data, highlighting key markets and growth trends. Include insights on what’s driving the sales surge in various regions.',
      text: '<h1>First title</h1><p>Some text here, lorem ipsum etc. etc.</p><img src="https://img.cnevpost.com/2021/06/28084155/a434560bcfda18cdd66dcdcfcb454d01.jpg"/><p>Some more text here</p>',
      createdAt: '2022-08-12',
      image: 'https://blog.evbox.com/hs-fs/hubfs/electric-car-power-charging.jpg?width=1920&name=electric-car-power-charging.jpg',
      author: 'Espen Holje',
    },
    {
      type: 'sales' as 'sales',
      title: 'EV Sales Surge : A look at the latest global market trends',
      description: 'Analysis of recent sales data, highlighting key markets and growth trends. Include insights on what’s driving the sales surge in various regions.',
      text: '<h1>First title</h1><p>Some text here, lorem ipsum etc. etc.</p><img src="https://img.cnevpost.com/2021/06/28084155/a434560bcfda18cdd66dcdcfcb454d01.jpg"/><p>Some more text here</p>',
      createdAt: '2022-08-12',
      image: 'https://blog.evbox.com/hs-fs/hubfs/electric-car-power-charging.jpg?width=1920&name=electric-car-power-charging.jpg',
      author: 'Espen Holje',
    },
    {
      type: 'sales' as 'sales',
      title: 'EV Sales Surge : A look at the latest global market trends',
      description: 'Analysis of recent sales data, highlighting key markets and growth trends. Include insights on what’s driving the sales surge in various regions.',
      text: '<h1>First title</h1><p>Some text here, lorem ipsum etc. etc.</p><img src="https://img.cnevpost.com/2021/06/28084155/a434560bcfda18cdd66dcdcfcb454d01.jpg"/><p>Some more text here</p>',
      createdAt: '2022-08-12',
      image: 'https://blog.evbox.com/hs-fs/hubfs/electric-car-power-charging.jpg?width=1920&name=electric-car-power-charging.jpg',
      author: 'Espen Holje',
    },
  ],
  // RESOURCES (API WOULD RECEIVE 'type' [sales/afterSales/marketing] and 'path' [path to folder] properties)


  getResources: (path: string) => {
    switch (path) {
      case 'Agents':
        return [
          {
            type: 'folder' as 'folder',
            name: 'Forhandler',
            updatedAt: '2024-08-29',
            size: 0,
            path: 'Forhandler',
          },
          {
            type: 'folder' as 'folder',
            name: 'Logo',
            updatedAt: '2024-08-29',
            size: 0,
            path: 'Logo',
          },
          {
            type: 'folder' as 'folder',
            name: 'Voyah Showroom',
            updatedAt: '2024-08-29',
            size: 0,
            path: 'Voyah%20Showroom',
          },
          {
            type: 'folder' as 'folder',
            name: 'Hubspot guide',
            updatedAt: '2024-08-29',
            size: 0,
            path: 'Hubspot%20guide',
          },
          {
            type: 'folder' as 'folder',
            name: 'Hubspot agent',
            updatedAt: '2024-08-29',
            size: 0,
            path: 'Hubspot%20agent',
          },
        ];
      case 'Agents%2FForhandler':
        return [
          {
            type: 'file' as 'file',
            name: 'Markedsportaler.pptx',
            updatedAt: '2024-08-29',
            size: 24200000,
            path: 'https://electricwayas.sharepoint.com/sites/Inside/Delte%20dokumenter/Marketing/SHARE%20EXTERNAL/Agents/Forhandler/Markedsportaler.pptx',
          }
        ];
      default:
        return [
          {
            type: 'folder' as 'folder',
            name: 'Agents',
            updatedAt: '2024-08-29',
            size: 0,
            path: 'Agents',
          },
          {
            type: 'folder' as 'folder',
            name: 'Brand elements',
            updatedAt: '2024-08-29',
            size: 0,
            path: 'Brand%20elements',
          },
          {
            type: 'folder' as 'folder',
            name: 'Brand and models',
            updatedAt: '2024-08-29',
            size: 0,
            path: 'Brand%20and%20models',
          },
          {
            type: 'folder' as 'folder',
            name: 'CRM',
            updatedAt: '2024-08-29',
            size: 0,
            path: 'CRM',
          },
          {
            type: 'folder' as 'folder',
            name: 'Customer Journey',
            updatedAt: '2024-08-29',
            size: 0,
            path: 'Customer%20Journey',
          },
        ];
    }
  },
  // CART


  getCart: [
    {
      code: '570401002',
      description: 'Left baggage carrier',
      quantity: 1,
      availableQuantity: 2,
      lineAmount: 3555.16,
    },
    {
      code: '100104003',
      description: 'Rear motor front mounting',
      quantity: 1,
      availableQuantity: 0,
      lineAmount: 3555.16,
    },
  ],
  // ADMINISTRATION


  administration: {
    // ENTITIES


    entities: {
      // ENTITY DROPDOWN


      getEntities: [
        'user',
        'role',
        'company',
      ],
      // ENTITY LIST


      getEntityRecords: (entity: string) => {
        switch (entity) {
          case 'user':
            return [
              {
                uuid: '550e-1234-5678-0000',
                externalId: 'user-external-id-1',
                roles: [
                  'Sales',
                  'Marketing',
                ],
                company: 'Company 1',
                name: 'John Doe',
                email: 'john.doe@gmail.com',
                phone: '+37122880101',
                createdAt: '2024-07-01',
                updatedAt: '2024-08-06',
              },
              {
                uuid: '550e-1234-5678-0001',
                externalId: 'user-external-id-2',
                roles: [
                  'Sales',
                  'After Sales',
                ],
                company: 'Company 2',
                name: 'Jane Doe',
                email: 'jane.doe@gmail.com',
                phone: '+37122880202',
                createdAt: '2024-07-03',
                updatedAt: '2024-07-03',
              },
              {
                uuid: '550e-1234-5678-0002',
                externalId: 'user-external-id-3',
                roles: [
                  'Marketing',
                ],
                company: 'Company 3',
                name: 'Josh Doe',
                email: 'josh.doe@gmail.com',
                phone: '+37122880303',
                createdAt: '2024-07-04',
                updatedAt: '2024-07-21',
              },
            ];
          case 'role':
            return [
              {
                uuid: '550e-1234-5678-1000',
                name: 'Sales',
                access: [
                  'sales.dashboard.*',
                  'sales.news.*',
                  'sales.inventory.create',
                  'sales.order_requests.create',
                  'sales.resources.*',
                ],
                createdAt: '2024-02-01',
                updatedAt: '2024-06-07',
              },
              {
                uuid: '550e-1234-5678-1001',
                name: 'After Sales',
                access: [
                  'after_sales.dashboard.*',
                  'after_sales.news.*',
                  'after_sales.claims.*',
                  'after_sales.technical_campaigns.view_all_related',
                  'after_sales.resources.*',
                ],
                createdAt: '2024-02-02',
                updatedAt: '2024-02-02',
              },
              {
                uuid: '550e-1234-5678-1002',
                name: 'Marketing',
                access: [
                  'marketing.dashboard.*',
                  'marketing.news.*',
                  'marketing.events.*',
                  'marketing.resources.*',
                ],
                createdAt: '2024-02-01',
                updatedAt: '2024-06-07',
              }
            ];
          case 'company':
            return [
              {
                uuid: '550e-1234-5678-2000',
                externalId: 'company-external_id-1',
                name: 'Company 1',
                contactPerson: 'John Doe',
                phone: '+37127001122',
                email: 'info@company1.com',
                city: 'Riga',
                countryCode: 'LV',
                createdAt: '2024-02-03',
                updatedAt: '2024-03-03',
              },
              {
                uuid: '550e-1234-5678-2001',
                externalId: 'company-external_id-2',
                name: 'Company 2',
                contactPerson: 'Jane Doe',
                phone: '+37127001133',
                email: 'info@company2.com',
                city: 'Oslo',
                country: 'Norway',
                createdAt: '2024-02-09',
                updatedAt: '2024-09-09',
              },
              {
                uuid: '550e-1234-5678-2002',
                externalId: 'company-external_id-3',
                name: 'Company 3',
                contactPerson: 'Josh Doe',
                phone: '+37127001144',
                email: 'info@company3.com',
                city: 'Stockholm',
                country: 'Sweden',
                createdAt: '2024-05-01',
                updatedAt: '2024-06-02',
              },
            ];
          default:
            return [];
        }
      },
      // USER VIEW


      getUserData: {
        uuid: '550e-1234-5678-0002',
        roles: [
          { uuid: '550e-1234-5678-1002', name: 'Marketing' },
        ],
        company: {
          uuid: '550e-1234-5678-2002',
          name: 'Company 3',
        },
        name: 'Josh Doe',
        email: 'josh.doe@gmail.com',
        phone: '+37122880303',
        roleOptions: [
          { uuid: '550e-1234-5678-1000', name: 'Sales' },
          { uuid: '550e-1234-5678-1001', name: 'After Sales' },
          { uuid: '550e-1234-5678-1002', name: 'Marketing' },
        ],
        companyOptions: [
          { uuid: '550e-1234-5678-2000', name: 'Company 1' },
          { uuid: '550e-1234-5678-2001', name: 'Company 2' },
          { uuid: '550e-1234-5678-2002', name: 'Company 3' },
        ],
      },
      // NEW USER VIEW


      getUserOptions: {
        roles: [
          { uuid: '550e-1234-5678-1000', name: 'Sales' },
          { uuid: '550e-1234-5678-1001', name: 'After Sales' },
          { uuid: '550e-1234-5678-1002', name: 'Marketing' },
        ],
        companies: [
          { uuid: '550e-1234-5678-2000', name: 'Company 1' },
          { uuid: '550e-1234-5678-2001', name: 'Company 2' },
          { uuid: '550e-1234-5678-2002', name: 'Company 3' },
        ],
      },
      // ROLE VIEW


      getRoleData: {
        uuid: '550e-1234-5678-1001',
        name: 'After Sales',
        access: [
          'after_sales.dashboard.*',
          'after_sales.news.*',
          'after_sales.claims.*',
          'after_sales.technical_campaigns.view_all',
          'after_sales.resources.*',
        ],
      },
    },
  },
  // MANAGEMENT


  management: {
    // ACCESS REQUESTS


    accessRequests: {
      getAccessRequests: [
        {
          uuid: '1',
          name: 'John Doe',
          companyName: 'Sulland Gruppen',
          accessRoles: ['Sales', 'After Sales'],
          status: 'pending' as 'pending',
        },
        {
          uuid: '2',
          name: 'Jane Doe',
          companyName: 'Sulland Gruppen',
          accessRoles: ['Marketing'],
          status: 'approved' as 'approved',
        },
        {
          uuid: '3',
          name: 'Josh Doe',
          companyName: 'Sulland Gruppen',
          accessRoles: ['Sales', 'After Sales', 'Marketing'],
          status: 'rejected' as 'rejected',
        },
      ],
      // ACCESS REQUEST VIEW


      getAccessRequestData: {
        uuid: '1',
        name: 'John Doe',
        email: 'john.doe@gmail.com',
        mobile: '+37127009922',
        companyName: 'Sulland Gruppen',
        jobTitle: 'Technician',
        accessRoles: ['Sales', 'After Sales'],
        status: 'pending' as 'pending',
      },
    },
    // USERS


    users: {
      // USER LIST


      getUsers: {
        data: [
          {
            uuid: '1',
            name: 'John Doe',
            email: 'john.doe@gmail.com',
            roles: [],
            isActive: true,
          },
          {
            uuid: '2',
            name: 'Jane Doe',
            email: 'jane.doe@gmail.com',
            roles: ['Marketing'],
            isActive: true,
          },
          {
            uuid: '3',
            name: 'Josh Doe',
            email: 'josh.doe@gmail.com',
            roles: ['Super Admin'],
            isActive: true,
          },
          {
            uuid: '4',
            name: 'Julia Doe',
            email: 'julia.doe@gmail.com',
            roles: ['Admin'],
            isActive: false,
          },
          {
            uuid: '5',
            name: 'Jake Doe',
            email: 'jake.doe@gmail.com',
            roles: ['Sales', 'After Sales'],
            isActive: true,
          },
        ],
        total: 5,
      },
      // USER VIEW


      getUserData: {
        uuid: '2',
        name: 'Jane Doe',
        email: 'jane.doe@gmail.com',
        phone: '+37122881144',
        company: 'Sulland Gruppen',
        roles: [
          { uuid: '3', name: 'Marketing' },
        ],
        roleOptions: [
          { uuid: '1', name: 'Sales' },
          { uuid: '2', name: 'After Sales' },
          { uuid: '3', name: 'Marketing' },
        ],
        isActive: true,
      },
    },
  },
};
