import { Outlet, ScrollRestoration } from 'react-router-dom';

import { ProgressBar } from 'src/components/progress-bar';

export function LayoutWrapper() {
  return (
    <>
      <ProgressBar />

      <ScrollRestoration />

      <Outlet />
    </>
  );
}
