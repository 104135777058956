import { lazy } from 'react';

import { Navigate } from 'src/routes/components';

import { MainLayout } from 'src/layouts/core';

import { useHasAccess } from 'src/auth/hooks';
import { PathGuard, RoleRouteGuard, AuthGuardWrapper } from 'src/auth/guard';

import { errorBoundary } from './main';
import { companyOptionsLoader } from '../utils';

// ----------------------------------------------------------------------

const Dashboard = lazy(() => import('src/pages/sales/dashboard'));

const NewsList = lazy(() => import('src/pages/sales/news'));
const ViewNews = lazy(() => import('src/pages/sales/news/view'));

const InventoryList = lazy(() => import('src/pages/sales/inventory'));

const OrderRequestList = lazy(() => import('src/pages/sales/order-requests'));
const ViewOrderRequest = lazy(() => import('src/pages/sales/order-requests/view'));
const NewOrderRequest = lazy(() => import('src/pages/sales/order-requests/new'));

const OrderList = lazy(() => import('src/pages/sales/orders'));
const ViewOrder = lazy(() => import('src/pages/sales/orders/view'));

const SalesReporting = lazy(() => import('src/pages/sales/sales-reporting'));
const ViewSalesReport = lazy(() => import('src/pages/sales/sales-reporting/view'));

const PriceList = lazy(() => import('src/pages/sales/price-list'));

const AppUserBinding = lazy(() => import('src/pages/sales/app-user-binding'));

const ResourceList = lazy(() => import('src/pages/sales/resources'));

// ----------------------------------------------------------------------

const layoutContent = (
  <MainLayout>
    {errorBoundary}
  </MainLayout>
);

export function SalesRoutes() {
  const { hasAccess: hasOrderRequestsAccess } = useHasAccess('sales.order_requests.view_all');
  const { hasAccess: hasOrdersAccess } = useHasAccess('sales.orders.view_all');

  return [
    {
      path: 'sales',
      element: <AuthGuardWrapper>{layoutContent}</AuthGuardWrapper>,
      children: [
        {
          element: (
            <RoleRouteGuard requiredAccess="sales.dashboard.view_all" navigateTo="/">
              <Navigate to="/sales/dashboard" replace />
            </RoleRouteGuard>
          ),
          index: true,
        },
        {
          path: 'dashboard',
          element: (
            <RoleRouteGuard requiredAccess="sales.dashboard.view_all" navigateTo="/">
              <Dashboard />
            </RoleRouteGuard>
          ),
        },
        {
          path: 'news',
          children: [
            {
              element: (
                <RoleRouteGuard requiredAccess="sales.news.view_all" navigateTo="/">
                  <NewsList />
                </RoleRouteGuard>
              ),
              index: true,
            },
            {
              path: 'view',
              element: (
                <RoleRouteGuard requiredAccess="sales.news.view_all" navigateTo="/">
                  <ViewNews />
                </RoleRouteGuard>
              ),
            },
          ],
        },
        {
          path: 'inventory',
          element: (
            <RoleRouteGuard requiredAccess="sales.inventory.view_all" navigateTo="/">
              <InventoryList />
            </RoleRouteGuard>
          ),
        },
        {
          path: 'order-requests',
          children: [
            {
              element: (
                <RoleRouteGuard
                  requiredAccess={['sales.order_requests.view_related', 'sales.order_requests.view_all_related', 'sales.order_requests.view_all']}
                  operator="or"
                  navigateTo="/"
                >
                  <OrderRequestList />
                </RoleRouteGuard>
              ),
              loader: () => companyOptionsLoader(hasOrderRequestsAccess),
              shouldRevalidate: () => false,
              index: true,
            },
            {
              path: 'view',
              children: [
                {
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['sales.order_requests.view_related', 'sales.order_requests.view_all_related', 'sales.order_requests.view_all']}
                      operator="or"
                      navigateTo="/"
                    >
                      <Navigate to="/sales/order-requests" replace />
                    </RoleRouteGuard>
                  ),
                  index: true,
                },
                {
                  path: ':uuid',
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['sales.order_requests.view_related', 'sales.order_requests.view_all_related', 'sales.order_requests.view_all']}
                      operator="or"
                      navigateTo="/"
                    >
                      <ViewOrderRequest />
                    </RoleRouteGuard>
                  ),
                },
              ],
            },
            {
              path: 'new',
              element: (
                <RoleRouteGuard requiredAccess="sales.order_requests.create" navigateTo="/">
                  <NewOrderRequest />
                </RoleRouteGuard>
              ),
            },
          ],
        },
        {
          path: 'orders',
          children: [
            {
              element: (
                <RoleRouteGuard
                  requiredAccess={['sales.orders.view_related', 'sales.orders.view_all_related', 'sales.orders.view_all']}
                  operator="or"
                  navigateTo="/"
                >
                  <OrderList />
                </RoleRouteGuard>
              ),
              loader: () => companyOptionsLoader(hasOrdersAccess),
              shouldRevalidate: () => false,
              index: true,
            },
            {
              path: 'view',
              children: [
                {
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['sales.orders.view_related', 'sales.orders.view_all_related', 'sales.orders.view_all']}
                      operator="or"
                      navigateTo="/"
                    >
                      <Navigate to="/sales/orders" replace />
                    </RoleRouteGuard>
                  ),
                  index: true,
                },
                {
                  path: ':uuid',
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['sales.orders.view_related', 'sales.orders.view_all_related', 'sales.orders.view_all']}
                      operator="or"
                      navigateTo="/"
                    >
                      <ViewOrder />
                    </RoleRouteGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'sales-reporting',
          children: [
            {
              element: (
                <RoleRouteGuard
                  requiredAccess={['sales.sales_reporting.view_all_related', 'sales.sales_reporting.view_all']}
                  operator="or"
                  navigateTo="/"
                >
                  <SalesReporting />
                </RoleRouteGuard>
              ),
              index: true,
            },
            {
              path: 'view',
              element: (
                <RoleRouteGuard
                  requiredAccess={['sales.sales_reporting.view_all_related', 'sales.sales_reporting.view_all']}
                  operator="or"
                  navigateTo="/"
                >
                  <ViewSalesReport />
                </RoleRouteGuard>
              ),
            },
          ],
        },
        {
          path: 'price-list',
          element: (
            <RoleRouteGuard requiredAccess="sales.price_list.view_all" navigateTo="/">
              <PriceList />
            </RoleRouteGuard>
          ),
        },
        {
          path: 'app-user-binding',
          element: (
            <RoleRouteGuard requiredAccess="sales.app_user_binding.create" navigateTo="/">
              <AppUserBinding />
            </RoleRouteGuard>
          ),
        },
        {
          path: 'resources',
          children: [
            {
              element: (
                <RoleRouteGuard requiredAccess="sales.resources.view_all" navigateTo="/">
                  <Navigate to="/sales/resources/path=" replace />
                </RoleRouteGuard>
              ),
              index: true,
            },
            {
              path: ':path',
              element: (
                <RoleRouteGuard requiredAccess="sales.resources.view_all" navigateTo="/">
                  <PathGuard navigateTo="/sales/resources/path=">
                    <ResourceList />
                  </PathGuard>
                </RoleRouteGuard>
              ),
            },
          ],
        },
      ],
    },
  ];
}
