import type { FallbackProps } from 'react-error-boundary';

import { m } from 'framer-motion';
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { RouterLink } from 'src/routes/components';

import { varAlpha } from 'src/theme/styles';
import { Main, CompactContent } from 'src/layouts/simple/main';
import { LayoutSection } from 'src/layouts/core/layout-section';
import { ServerErrorIllustration } from 'src/assets/illustrations';

import { varBounce, MotionContainer } from 'src/components/animate';

// ----------------------------------------------------------------------

export function ServerErrorView({ error, resetErrorBoundary }: FallbackProps) {
  const { t } = useTranslation();

  const theme = useTheme();

  const location = useLocation();

  const errorLocation = useRef(location.pathname);

  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      resetErrorBoundary();
    }
  }, [location.pathname, resetErrorBoundary]);

  return (
    <LayoutSection
      headerSection={null}
      footerSection={null}
      cssVars={{
        '--layout-simple-content-compact-width': '448px',
      }}
      sx={{ bgcolor: () => varAlpha(theme.vars.palette.grey['500Channel'], 0.04) }}
    >
      <Main>
        <CompactContent>
          <Container component={MotionContainer}>
            <m.div variants={varBounce().in}>
              <Typography variant="h3" sx={{ mb: 2 }}>
                {t('internalServerError')}
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <Typography sx={{ color: 'text.secondary' }}>
                {error.message}
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <ServerErrorIllustration sx={{ my: { xs: 5, sm: 10 } }} />
            </m.div>

            <Button component={RouterLink} href="/" size="large" variant="contained">
              {t('goHome')}
            </Button>
          </Container>
        </CompactContent>
      </Main>
    </LayoutSection>
  );
}
