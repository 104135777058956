import { lazy } from 'react';

import { Navigate } from 'src/routes/components';

import { MainLayout } from 'src/layouts/core';

import { RoleRouteGuard, AuthGuardWrapper } from 'src/auth/guard';

import { errorBoundary } from './main';

// ----------------------------------------------------------------------

const VehicleCard = lazy(() => import('src/pages/vehicle-card'));

// ----------------------------------------------------------------------

const layoutContent = (
  <MainLayout>
    {errorBoundary}
  </MainLayout>
);

export function VehicleCardRoutes() {
  return [
    {
      path: 'vehicle-card',
      element: <AuthGuardWrapper>{layoutContent}</AuthGuardWrapper>,
      children: [
        { element: <Navigate to="/" replace />, index: true },
        {
          path: ':uuid',
          element: (
            <RoleRouteGuard requiredAccess="general.vehicle_card.view_all" navigateTo="/">
              <VehicleCard />
            </RoleRouteGuard>
          ),
        },
      ],
    },
  ];
}
