import { lazy } from 'react';

import { Navigate } from 'src/routes/components';

import { MainLayout } from 'src/layouts/core';

import { useHasAccess } from 'src/auth/hooks';
import { PathGuard, RoleRouteGuard, AuthGuardWrapper } from 'src/auth/guard';

import { errorBoundary } from './main';
import { companyOptionsLoader } from '../utils';

// ----------------------------------------------------------------------

const Dashboard = lazy(() => import('src/pages/after-sales/dashboard'));

const NewsList = lazy(() => import('src/pages/after-sales/news'));
const ViewNews = lazy(() => import('src/pages/after-sales/news/view'));

const ClaimList = lazy(() => import('src/pages/after-sales/claims'));
const ViewClaim = lazy(() => import('src/pages/after-sales/claims/view'));
const NewClaim = lazy(() => import('src/pages/after-sales/claims/new'));

const NewService = lazy(() => import('src/pages/after-sales/service/new'));

const TechnicalCampaignList = lazy(() => import('src/pages/after-sales/technical-campaigns'));
const ViewTechnicalCampaign = lazy(() => import('src/pages/after-sales/technical-campaigns/view'));
const NewTechnicalCampaign = lazy(() => import('src/pages/after-sales/technical-campaigns/new'));

const SparePartList = lazy(() => import('src/pages/after-sales/spare-parts'));
const Order = lazy(() => import('src/pages/after-sales/spare-parts/order'));

const OrderList = lazy(() => import('src/pages/after-sales/orders'));
const ViewOrder = lazy(() => import('src/pages/after-sales/orders/view'));

const ResourceList = lazy(() => import('src/pages/after-sales/resources'));

// ----------------------------------------------------------------------

const layoutContent = (
  <MainLayout>
    {errorBoundary}
  </MainLayout>
);

export function AfterSalesRoutes() {
  const { hasAccess: hasClaimsAccess } = useHasAccess('after_sales.claims.view_all');
  const { hasAccess: hasOrdersAccess } = useHasAccess('after_sales.orders.view_all');

  return [
    {
      path: 'after-sales',
      element: <AuthGuardWrapper>{layoutContent}</AuthGuardWrapper>,
      children: [
        {
          element: (
            <RoleRouteGuard requiredAccess="after_sales.dashboard.view_all" navigateTo="/">
              <Navigate to="/after-sales/dashboard" replace />
            </RoleRouteGuard>
          ),
          index: true,
        },
        {
          path: 'dashboard',
          element: (
            <RoleRouteGuard requiredAccess="after_sales.dashboard.view_all" navigateTo="/">
              <Dashboard />
            </RoleRouteGuard>
          ),
        },
        {
          path: 'news',
          children: [
            {
              element: (
                <RoleRouteGuard requiredAccess="after_sales.news.view_all" navigateTo="/">
                  <NewsList />
                </RoleRouteGuard>
              ),
              index: true,
            },
            {
              path: 'view',
              element: (
                <RoleRouteGuard requiredAccess="after_sales.news.view_all" navigateTo="/">
                  <ViewNews />
                </RoleRouteGuard>
              ),
            },
          ],
        },
        {
          path: 'claims',
          children: [
            {
              element: (
                <RoleRouteGuard
                  requiredAccess={['after_sales.claims.view_related', 'after_sales.claims.view_all_related', 'after_sales.claims.view_all']}
                  operator="or"
                  navigateTo="/"
                >
                  <ClaimList />
                </RoleRouteGuard>
              ),
              loader: () => companyOptionsLoader(hasClaimsAccess),
              shouldRevalidate: () => false,
              index: true,
            },
            {
              path: 'view',
              children: [
                {
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['after_sales.claims.view_related', 'after_sales.claims.view_all_related', 'after_sales.claims.view_all']}
                      operator="or"
                      navigateTo="/"
                    >
                      <Navigate to="/after-sales/claims" replace />
                    </RoleRouteGuard>
                  ),
                  index: true,
                },
                {
                  path: ':uuid',
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['after_sales.claims.view_related', 'after_sales.claims.view_all_related', 'after_sales.claims.view_all']}
                      operator="or"
                      navigateTo="/"
                    >
                      <ViewClaim />
                    </RoleRouteGuard>
                  ),
                },
              ],
            },
            {
              path: 'new',
              children: [
                {
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['after_sales.claims.view_related', 'after_sales.claims.view_all_related', 'after_sales.claims.view_all']}
                      operator="or"
                      navigateTo="/"
                    >
                      <Navigate to="/after-sales/claims" replace />
                    </RoleRouteGuard>
                  ),
                  index: true,
                },
                {
                  path: ':uuid',
                  element: (
                    <RoleRouteGuard requiredAccess="after_sales.claims.create" navigateTo="/">
                      <NewClaim />
                    </RoleRouteGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'service',
          children: [
            {
              element: (
                <RoleRouteGuard requiredAccess="after_sales.dashboard.view_all" navigateTo="/">
                  <Navigate to="/after-sales/dashboard" replace />
                </RoleRouteGuard>
              ),
              index: true,
            },
            {
              path: 'new',
              children: [
                {
                  element: (
                    <RoleRouteGuard requiredAccess="after_sales.dashboard.view_all" navigateTo="/">
                      <Navigate to="/after-sales/dashboard" replace />
                    </RoleRouteGuard>
                  ),
                  index: true,
                },
                {
                  path: ':uuid',
                  element: (
                    <RoleRouteGuard requiredAccess="after_sales.service_history.create" navigateTo="/">
                      <NewService />
                    </RoleRouteGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'technical-campaigns',
          children: [
            {
              element: (
                <RoleRouteGuard requiredAccess={['after_sales.technical_campaigns.view_all', 'after_sales.technical_campaigns.create']} navigateTo="/">
                  <TechnicalCampaignList />
                </RoleRouteGuard>
              ),
              index: true,
            },
            {
              path: 'view',
              children: [
                {
                  element: (
                    <RoleRouteGuard requiredAccess="after_sales.dashboard.view_all" navigateTo="/">
                      <Navigate to="/after-sales/dashboard" replace />
                    </RoleRouteGuard>
                  ),
                  index: true,
                },
                {
                  path: ':uuid',
                  element: (
                    <RoleRouteGuard requiredAccess="after_sales.technical_campaigns.view_all" navigateTo="/">
                      <ViewTechnicalCampaign />
                    </RoleRouteGuard>
                  ),
                },
              ],
            },
            {
              path: 'new',
              element: (
                <RoleRouteGuard requiredAccess="after_sales.technical_campaigns.create" navigateTo="/">
                  <NewTechnicalCampaign />
                </RoleRouteGuard>
              ),
            },
          ],
        },
        {
          path: 'spare-parts',
          children: [
            {
              element: (
                <RoleRouteGuard requiredAccess="after_sales.spare_parts.view_all" navigateTo="/">
                  <SparePartList />
                </RoleRouteGuard>
              ),
              index: true,
            },
            {
              path: 'order',
              element: (
                <RoleRouteGuard requiredAccess="after_sales.spare_parts.create" navigateTo="/">
                  <Order />
                </RoleRouteGuard>
              ),
            },
          ],
        },
        {
          path: 'orders',
          children: [
            {
              element: (
                <RoleRouteGuard
                  requiredAccess={['after_sales.orders.view_related', 'after_sales.orders.view_all_related', 'after_sales.orders.view_all']}
                  operator="or"
                  navigateTo="/"
                >
                  <OrderList />
                </RoleRouteGuard>
              ),
              loader: () => companyOptionsLoader(hasOrdersAccess),
              shouldRevalidate: () => false,
              index: true,
            },
            {
              path: 'view',
              children: [
                {
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['after_sales.orders.view_related', 'after_sales.orders.view_all_related', 'after_sales.orders.view_all']}
                      operator="or"
                      navigateTo="/"
                    >
                      <Navigate to="/after-sales/orders" replace />
                    </RoleRouteGuard>
                  ),
                  index: true,
                },
                {
                  path: ':uuid',
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['after_sales.orders.view_related', 'after_sales.orders.view_all_related', 'after_sales.orders.view_all']}
                      operator="or"
                      navigateTo="/"
                    >
                      <ViewOrder />
                    </RoleRouteGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'resources',
          children: [
            {
              element: (
                <RoleRouteGuard requiredAccess="after_sales.resources.view_all" navigateTo="/">
                  <Navigate to="/after-sales/resources/path=" replace />
                </RoleRouteGuard>
              ),
              index: true,
            },
            {
              path: ':path',
              element: (
                <RoleRouteGuard requiredAccess="after_sales.resources.view_all" navigateTo="/">
                  <PathGuard navigateTo="/after-sales/resources/path=">
                    <ResourceList />
                  </PathGuard>
                </RoleRouteGuard>
              ),
            },
          ],
        },
      ],
    },
  ];
}
